import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, VFlow } from 'bold-ui'
import { CidadaoAtendimentoSelectField, Form, FormRenderProps, SubmitButton, TextAreaField } from 'components/form'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { GESTAO_GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { FormApi } from 'final-form'
import { useAdicionarGarantiaAcessoCeoMutation } from 'graphql/hooks.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { convertGarantiaAcessoFormModelToInput } from '../convert-garantiaAcesso'
import { GarantiaAcessoFormModel } from '../model-garantiaAcesso'
import { garantiaAcessoValidator } from '../validator-garantiaAcesso'

const path = metaPath<GarantiaAcessoFormModel>()

interface GarantiaAcessoCeoFormViewProps {
  initialValues: GarantiaAcessoFormModel
  onRegistrosConflitantes(values: GarantiaAcessoFormModel): Promise<boolean>
  onResetFormFields: (formApi: FormApi) => void
  onSaveSuccess: () => void
}

export default function GarantiaAcessoCeoFormView(props: GarantiaAcessoCeoFormViewProps) {
  const { initialValues, onRegistrosConflitantes, onResetFormFields, onSaveSuccess } = props

  const [salvar] = useAdicionarGarantiaAcessoCeoMutation()

  const save = (values: GarantiaAcessoFormModel, formApi: FormApi) =>
    salvar({ variables: { input: convertGarantiaAcessoFormModelToInput(values) } }).then(() => {
      onSaveSuccess()
      onResetFormFields(formApi)
    })

  const handleSubmit = (values: GarantiaAcessoFormModel, formApi: FormApi) => {
    return onRegistrosConflitantes(values).then((confirmed) => confirmed && save(values, formApi))
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoFormModel>) => {
    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            <CidadaoAtendimentoSelectField
              name={path.cidadao}
              label='Cidadão'
              title='Cidadão'
              ativo
              required
              placeholder='Buscar por Nome completo, CPF ou CNS'
              obito={false}
              addCidadaoCallbackUrl={GESTAO_GARANTIA_ACESSO_PATH}
              verificarPresenteNaListaAtendimento={false}
            />
            <TextAreaField
              name={path.motivo}
              label='Motivo da consulta'
              title='Motivo da consulta'
              maxLength={200}
              required
              style={css`
                height: 5rem;
                resize: none;
              `}
            />
          </VFlow>
        </Cell>

        <Cell size={5}>
          <VFlow>
            <TipoServicoSelectField name={path.tipoServico} label='Tipo de serviço' title='Tipo de serviço' required />
          </VFlow>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={() => onResetFormFields(formProps.form)}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<GarantiaAcessoFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={garantiaAcessoValidator(true)}
    />
  )
}
