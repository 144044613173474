/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HeadingSection, Theme, useTheme, VFlow } from 'bold-ui'
import { purple } from 'bold-ui/lib/styles/colors'
import { DateField } from 'components/form'
import TipoGravidezSelectField from 'components/form/field/select/TipoGravidezSelectField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { max } from 'lodash'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { EncerrarGestacaoFormModel } from './model-preNatal'

interface EncerrarGestacaoFormProps {
  meta: MetaPath<EncerrarGestacaoFormModel>
}

export function EncerrarGestacaoForm(props: EncerrarGestacaoFormProps) {
  const { meta } = props

  const {
    prontuario: { preNatalAtivo },
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const theme = useTheme()

  const styles = createStyles(theme)

  const dataNascimentoAsDate = toDate(dataNascimento)
  const dataInicioW78AsDate = toDate(preNatalAtivo?.problema.ultimaEvolucao.dataInicio)

  return (
    <VFlow vSpacing={0.5} style={styles.wrapper}>
      <HeadingSection level={3} title='Encerrar gestação' />
      <Grid>
        <Cell size={6}>
          <TipoGravidezSelectField
            name={meta.tipoGravidez}
            initialValue={preNatalAtivo?.tipoGravidez}
            showAllEnumValues
          />
        </Cell>
        <Cell size={6}>
          <DateField
            name={meta.dataDesfecho}
            label='Data do desfecho'
            required
            minDate={max([dataNascimentoAsDate, dataInicioW78AsDate])}
            maxDate={iniciadoEm}
          />
        </Cell>
      </Grid>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    padding: 1rem;
    border: 1px solid ${purple.c60};
    border-radius: ${theme.radius.paper}px;
  `,
})
