import { css } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormFooter, RadioField, SubmitButton } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { FormApi } from 'final-form'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { downloadRelatorio } from '../downloadRelatorio'

export interface RelatorioOperacionalAuthorization {
  hasCriancaPermission: boolean
  hasGestantePermission: boolean
  hasHipertensaoPermission: boolean
}

interface AcompanhamentoSaudeRelatorioModalProps {
  disableGerarRelatorio: boolean
  filter: AcompanhamentoCondicaoSaudeQueryInput
  canAcessarModalRelatorio: boolean
  permissions: RelatorioOperacionalAuthorization
}
export interface AcompanhamentoSaudeRelatorioModel {
  tipoRelatorio: TipoRelatorio
}

export enum TipoRelatorio {
  CRIANCA = 'CRIANCA',
  GESTANTE = 'GESTANTE',
  RISCO_CARDIOVASCULAR = 'RISCO_CARDIOVASCULAR',
}

export function AcompanhamentoSaudeRelatorioModal(props: AcompanhamentoSaudeRelatorioModalProps) {
  const { disableGerarRelatorio, filter, canAcessarModalRelatorio, permissions } = props
  const path = metaPath<AcompanhamentoSaudeRelatorioModel>()
  const [isOpen, setOpen] = useState(false)
  const alert = useAlert()
  const { analytics } = useFirebase()

  const renderForm = (props: FormRenderProps<AcompanhamentoSaudeRelatorioModel>) => {
    const handleCancelar = () => {
      setOpen(false)
      props.form.reset()
    }

    return (
      <Modal size='small' open={isOpen} onClose={handleCancelar} closeOnBackdropClick={false}>
        <ModalBody>
          <Heading level={1}>Gerar relatório operacional</Heading>
          <Alert type='info' style={{ padding: '0.5rem ', margin: '1rem 0' }}>
            Serão apresentados apenas cidadãos que estejam vinculados a um responsável familiar e este vinculado a um
            domicílio do território. Caso algum relatório esteja desabilitado, verificar suas permissões.
          </Alert>
          <p>Selecione qual relatório deseja gerar:</p>
          <VFlow vSpacing={0.5} style={{ marginTop: '1.5rem' }}>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.CRIANCA}
                label='Relatório operacional de Crianças menores de 5 anos'
                disabled={!permissions.hasCriancaPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FP, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.GESTANTE}
                label='Relatório operacional de Gestante/Puérpera'
                disabled={!permissions.hasGestantePermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.RISCO_CARDIOVASCULAR}
                label='Relatório operacional de Risco cardiovascular'
                disabled={!permissions.hasHipertensaoPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCancelar}>Cancelar</Button>
            <Tooltip text={!props.values?.tipoRelatorio && 'Selecione o tipo de relatório'}>
              <SubmitButton kind='primary' disabled={!props.values?.tipoRelatorio} handleSubmit={props.handleSubmit}>
                Gerar relatório
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleSubmit = (
    values: AcompanhamentoSaudeRelatorioModel,
    formApi: FormApi<AcompanhamentoSaudeRelatorioModel>
  ) => {
    analytics.logEvent('relatorio_ope_acomp_cond_saude', { 'relatorio ': values.tipoRelatorio })
    return downloadRelatorio(values.tipoRelatorio, filter)
      .then(() => {
        alert('success', 'Relatório operacional gerado com sucesso.')
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
      .catch((errorMessage) => {
        alert('warning', errorMessage)
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
  }

  const hintBtnGerarRelatorio = canAcessarModalRelatorio
    ? disableGerarRelatorio && filter?.pageParams?.size > 500
      ? 'A busca de cidadãos retornou mais de 500 resultados. Refine melhor a busca para poder gerar relatórios operacionais.'
      : 'A busca de cidadãos não encontrou nenhum resultado com os filtros aplicados.'
    : 'Acesso não permitido.'

  const styles = {
    radioContainer: css`
      display: flex;
      align-items: center;
    `,
  }

  return (
    <>
      <FormFooter>
        <Tooltip text={disableGerarRelatorio && hintBtnGerarRelatorio}>
          <Button
            kind='primary'
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
            disabled={disableGerarRelatorio}
            onClick={() => setOpen(true)}
          >
            <Icon icon='fileWithItemsOutline' style={{ marginRight: '1rem' }} />
            <span>Gerar relatório operacional</span>
          </Button>
        </Tooltip>
      </FormFooter>
      <Form<AcompanhamentoSaudeRelatorioModel> render={renderForm} onSubmit={handleSubmit} />
    </>
  )
}
