/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, InfoLabel, Modal, ModalBody, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { PageLoading } from 'components/loading'
import { TitleGroup } from 'components/TitleGroup'
import { useCuidadoCompartilhadoQuery } from 'graphql/hooks.generated'
import { CuidadoCompartilhadoEvolucoes } from 'view/cuidados-compartilhados/cuidado-compartilhado/components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { DateTimeInfoCuidadoCompartilhado } from 'view/cuidados-compartilhados/cuidado-compartilhado/components/DateTimeInfoCuidadoCompartilhado'
import { ClassificacaoPrioridadeCuidadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { lotacaoCuidadoCompartilhadoFormatter } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import StatusSquare from '../../StatusSquare'
import CiapCidLabel from './components/CiapCidLabel'

interface DiscussaoCuidadoCompartilhadoModalProps {
  cuidadoCompartilhadoId: ID
  handleClose(): void
}

export function DiscussaoCuidadoCompartilhadoModal(props: DiscussaoCuidadoCompartilhadoModalProps) {
  const { cuidadoCompartilhadoId, handleClose } = props

  const {
    data: { cuidadoCompartilhado },
    loading: isLoading,
  } = useCuidadoCompartilhadoQuery({
    variables: {
      id: cuidadoCompartilhadoId,
    },
  })

  return (
    <Modal open onClose={handleClose}>
      <ModalBody>
        {isLoading ? (
          <PageLoading message='Carregando dados do cuidado compartilhado...' />
        ) : (
          <VFlow>
            <TitleGroup title='Discussão de caso' />
            <VFlow vSpacing={0.5}>
              <InfoLabel title='Problemas e condições'>
                <CiapCidLabel ciap={cuidadoCompartilhado.ciap} cid10={cuidadoCompartilhado.cid10} />
              </InfoLabel>
              <InfoLabel title='Discussão de caso clínico'>
                <Text dangerouslySetInnerHTML={{ __html: cuidadoCompartilhado.discussao }} style={styles.richText} />
              </InfoLabel>
              <HFlow hSpacing={2}>
                <InfoLabel title='Prioridade'>
                  <StatusSquare
                    color={ClassificacaoPrioridadeCuidadoRecord[cuidadoCompartilhado.classificacaoPrioridadeAtual].cor}
                    description={
                      ClassificacaoPrioridadeCuidadoRecord[cuidadoCompartilhado.classificacaoPrioridadeAtual].descricao
                    }
                  />
                </InfoLabel>
                <DateTimeInfoCuidadoCompartilhado cuidadoCompartilhado={cuidadoCompartilhado} />
              </HFlow>
              <InfoLabel title='Profissional solicitante'>
                <Text>{lotacaoCuidadoCompartilhadoFormatter(cuidadoCompartilhado.lotacaoSolicitante)}</Text>
              </InfoLabel>
              <InfoLabel title='Profissional executante'>
                <Text>{lotacaoCuidadoCompartilhadoFormatter(cuidadoCompartilhado.lotacaoExecutanteAtual)}</Text>
              </InfoLabel>
            </VFlow>
            <Box style={styles.box}>
              <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhado.id} />
            </Box>
          </VFlow>
        )}
      </ModalBody>
    </Modal>
  )
}

const styles = {
  box: css`
    padding: 0.5rem 0;
  `,
  richText: css`
    word-break: break-all;
  `,
}
