import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import React, { Fragment } from 'react'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { cuidadoCompartilhadoClassificacaoPrioridadeRecord } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'
import {
  formatCiapCid,
  lotacaoCuidadoCompartilhadoFormatter,
} from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { HistoricoPlanoPecCuidadoCompartilhado } from './HistoricoPlanoPecCuidadoCompartilhadoList'

interface HistoricoPlanoPecCuidadoCompartilhadoPanelProps {
  cuidadoCompartilhado: HistoricoPlanoPecCuidadoCompartilhado
}

export const HistoricoPlanoPecCuidadoCompartilhadoPanel = (props: HistoricoPlanoPecCuidadoCompartilhadoPanelProps) => {
  const {
    lotacaoExecutanteAtual,
    ciap,
    cid10,
    classificacaoPrioridadeAtual,
    discussao,
    cidadaoAceitaAtendTic,
  } = props.cuidadoCompartilhado
  const {
    label: classificacaoRiscoLabel,
    mainColor: classificacaoRiscoColor,
  } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[classificacaoPrioridadeAtual]

  return (
    <Fragment>
      <Grid gap={2}>
        <Cell size={8}>
          <InfoLabel title='Compartilhado para'>
            <Text>{lotacaoCuidadoCompartilhadoFormatter(lotacaoExecutanteAtual)}</Text>
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Prioridade'>
            <StatusSquare description={classificacaoRiscoLabel} color={classificacaoRiscoColor} />
          </InfoLabel>
        </Cell>
      </Grid>
      <Grid gap={2}>
        <Cell size={12}>
          <InfoLabel title='Problemas/condições'>
            <Text>{formatCiapCid(ciap, cid10)}</Text>
          </InfoLabel>
        </Cell>
        <Cell size={12}>
          <InfoLabel title='Discussão de caso clínico'>
            <Text dangerouslySetInnerHTML={{ __html: discussao }} />
          </InfoLabel>
        </Cell>
        <Cell size={12}>
          <InfoLabel title='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediada por tecnologia?'>
            <Text>{cidadaoAceitaAtendTic ? 'Sim' : 'Não'}</Text>
          </InfoLabel>
        </Cell>
      </Grid>
    </Fragment>
  )
}
