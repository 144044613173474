import { confirm } from 'components/modals/confirm'
import { useUnidadesSaudeByGarantiaAcessoCidadaoQuery } from 'graphql/hooks.generated'
import { useCallback } from 'react'

import { CidadaoGarantiaAcesso, GarantiaAcessoEdicaoModel, GarantiaAcessoFormModel } from '../model-garantiaAcesso'
import { concatNomesUnidadesSaude } from '../utils/utils-garantiaAcesso'

export function useGestaoGarantiaAcessoConflitantes() {
  const { refetch: refetchUnidadesSaudeByGarantiaAcessoCidadaoQuery } = useUnidadesSaudeByGarantiaAcessoCidadaoQuery({
    skip: true,
  })

  const verificarRegistrosConflitantes = useCallback(
    async (
      currentUnidadeSaudeId: ID,
      cidadao: CidadaoGarantiaAcesso,
      isCEO: Boolean,
      values: GarantiaAcessoFormModel | GarantiaAcessoEdicaoModel,
      isEdicao: Boolean = false
    ) => {
      if (!cidadao) return true

      let confirmed = true
      const {
        data: { unidadesSaudeByGarantiaAcessoCidadao: unidadesSaude },
      } = await refetchUnidadesSaudeByGarantiaAcessoCidadaoQuery({
        input: {
          cidadaoId: cidadao.id,
          tipoAtendimento: values.tipoAtendimento?.value,
          tipoServico: values.tipoServico?.id,
        },
      })

      const isPresenteNestaUbs = unidadesSaude.some((unidadeSaude) => unidadeSaude.id === currentUnidadeSaudeId)
      if (!isPresenteNestaUbs && unidadesSaude.length > 0) {
        const nomeCidadao = (cidadao.nomeSocial ?? cidadao.nome).titleCase()
        const titleQuestion = isEdicao ? 'a edição?' : 'o registro?'
        const tipoRegistroLabel = isCEO ? 'serviço' : 'atendimento'
        const nomesUnidadesSaude = unidadesSaude
          .filter((item) => item.id !== currentUnidadeSaudeId)
          .map((ubs) => ubs.nome)
        const nomesConcatenadosUnidadesSaude = concatNomesUnidadesSaude(nomesUnidadesSaude, isCEO)

        confirmed = await confirm({
          title: `Deseja continuar com ${titleQuestion}`,
          body: `${nomeCidadao} já possui um registro para o tipo de ${tipoRegistroLabel} selecionado ${nomesConcatenadosUnidadesSaude}.`,
          confirmLabel: `${isEdicao ? 'Continuar' : 'Adicionar'}`,
          cancelLabel: 'Voltar',
        })()
      }
      return confirmed
    },
    [refetchUnidadesSaudeByGarantiaAcessoCidadaoQuery]
  )

  return { verificarRegistrosConflitantes }
}
