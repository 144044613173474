import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'

import { RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO } from '../common/model'
import { RelatoriosGerenciaisTableWrapper } from '../components/RelatoriosGerenciaisTableWrapper'
import { atendimentoProfissionalKeyMapping } from './atendimentoKeyMapping'
import { AtendimentoProfissional } from './AtendimentoProfissional'

const OPERATIONS = {
  build: '/bi/atendimento-profissional/build',
  meta: '/bi/atendimento-profissional/meta',
}

const NAME = 'Relatório gerencial de atendimentos'

export function AtendimentosProfissionalView() {
  const {
    meta,
    setDateFilter,
    setCiapCidFilter,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    ...rest
  } = useTreeBuilder<AtendimentoProfissional>({
    keyMapping: atendimentoProfissionalKeyMapping,
    operations: OPERATIONS,
  })

  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

  const handleSubmit = useCallback(
    (dateRange: DateRange, periodUnit: string, filtroCiapCid: Set<string>) => {
      setDateFilter(dateRange, periodUnit)
      setCiapCidFilter(filtroCiapCid)
      setDateRangeFilter(dateRange)
    },
    [setCiapCidFilter, setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, { relatorio: NAME })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório gerencial de atendimentos apresenta a contagem dos atendimentos individuais e odontológicos
            realizados. A origem dos atendimentos deste relatório são o CDS e o PEC. Os atendimentos do tipo "Escuta
            inicial / Orientação" não serão apresentados.
          </Alert>
          <RelatoriosGerenciaisTableWrapper
            loading={isSearchingMeta}
            isEmpty={isMetaEmpty}
            onSubmit={handleSubmit}
            isRelatorioAtendimentos
          >
            {meta && (
              <PivotTable<AtendimentoProfissional>
                title={NAME}
                meta={meta}
                keyMapping={atendimentoProfissionalKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                {...rest}
              />
            )}
          </RelatoriosGerenciaisTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
