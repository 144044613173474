import { Alert, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { DadosServidoresQuery } from 'graphql/types.generated'
import React from 'react'

import { ConfiguracoesDadosServidorInstalacaoBox } from './ConfiguracoesDadosServidorInstalacaoBox'
import { ConfiguracoesServidorSMTPBox } from './ConfiguracoesServidorSMTPBox'

export interface ConfiguracoesServidoresBoxProps {
  dadosServidor: DadosServidoresQuery['servidores']['dadosServidor']
  internetConnection: boolean
  servidorSMTP: DadosServidoresQuery['servidores']['servidorSMTP']
  refetch(): void
}

export const ConfiguracoesServidoresBox = (props: ConfiguracoesServidoresBoxProps) => {
  const { servidorSMTP, dadosServidor, refetch, internetConnection } = props
  const flags = useFlags()

  return (
    <VFlow vSpacing={1}>
      {flags.PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED ? (
        <Alert type='info' inline>
          Configure o servidor da sua instalação e um servidor SMTP para disponibilizar a opção de "Esqueci minha senha"
          na tela de login. Esta opção permite que os profissionais redefinam suas senhas via e-mail. O servidor SMTP
          também é utilizado para enviar as prescrições e atestados digitais aos cidadãos via e-mail.
        </Alert>
      ) : (
        <Alert type='info' inline>
          Configure o servidor da sua instalação e um servidor SMTP para disponibilizar a opção de "Esqueci minha senha"
          na tela de login. Esta opção permite que os profissionais redefinam suas senhas via e-mail. O servidor SMTP
          também é utilizado para enviar as prescrições digitais aos cidadãos via e-mail.
        </Alert>
      )}

      <ConfiguracoesDadosServidorInstalacaoBox initialValues={dadosServidor} refetch={refetch} />
      <ConfiguracoesServidorSMTPBox
        internetConnection={internetConnection}
        initialValues={
          servidorSMTP && servidorSMTP.ativo
            ? {
                ...servidorSMTP,
                emailRemetente: servidorSMTP && servidorSMTP.email === servidorSMTP.usuario,
              }
            : ({} as any)
        }
        additionalInfo={servidorSMTP}
        refetch={refetch}
      />
    </VFlow>
  )
}
