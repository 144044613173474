import { Breadcrumb } from 'components/breadcrumb'
import qs from 'qs'
import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import AtendimentoListing from 'view/atendimentos/components/AtendimentoListing'

import GarantiaAcessoForm from './cadastro/GarantiaAcessoForm'
import GarantiaAcessoTable from './GarantiaAcessoTable'
import GarantiaAcessoTableFilter from './GarantiaAcessoTableFilter'
import { GarantiaAcessoFilterModel } from './model-garantiaAcesso'

interface UrlParams {
  cidadaoId: string
}

export default function GarantiaAcessoView() {
  const location = useLocation()
  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const [filter, setFilter] = useState<GarantiaAcessoFilterModel>({
    nomeCpfCns: '',
    equipes: [],
    tiposAtendimento: [],
    tiposServico: [],
    pageParams: {
      size: 15,
      sort: ['dataEntrada'],
    },
  })

  const onChangeFilter = useCallback(
    (filter: GarantiaAcessoFilterModel) => {
      setFilter((prevState) => ({
        ...prevState,
        nomeCpfCns: filter.nomeCpfCns || '',
        equipes: filter.equipes || [],
        tiposAtendimento: filter.tiposAtendimento || [],
        tiposServico: filter.tiposServico || [],
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
      }))
    },
    [setFilter]
  )

  return (
    <>
      <Breadcrumb title='Gestão da garantia do acesso' />
      <AtendimentoListing
        title='Gestão da garantia do acesso'
        heading={<GarantiaAcessoForm cidadaoId={urlParams.cidadaoId} />}
        filter={<GarantiaAcessoTableFilter onChange={onChangeFilter} />}
        children={<GarantiaAcessoTable onChangeFilter={setFilter} filter={filter} />}
        footer={undefined}
      />
    </>
  )
}
