/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, Heading, Icon, VFlow } from 'bold-ui'
import { StickyButton } from 'components/sticky-button/StickyButton'
import { useState } from 'react'

import { NewsVersion } from './hooks/useNewsItems'
import { NewsInternalPanel } from './NewsInternalPanel'
import { SideMenuNews } from './sideMenu/SideMenuNews'

const CURRENT_VERSION: NewsVersion = '5-2'

export function News() {
  const [selectedVersion, setSelectedVersion] = useState<NewsVersion>(CURRENT_VERSION)

  const handleItemClick = (version: NewsVersion) => {
    setSelectedVersion(version)
  }
  return (
    <VFlow>
      <Heading level={1}>Novidades</Heading>
      <Grid>
        <Cell size={3} style={styles.cellSideMenuNews}>
          <SideMenuNews
            currentVersion={CURRENT_VERSION}
            selectedVersion={selectedVersion}
            onItemClick={handleItemClick}
          />
        </Cell>
        <Cell size={7}>
          <VFlow>
            <Alert type='info' inline>
              <strong>O PEC é frequentemente aprimorado com melhorias e correções!</strong> Para conhecer as próximas
              novidades, o sistema deve ser atualizado com a versão mais recente disponível.
            </Alert>
            <VFlow>
              <NewsInternalPanel version={selectedVersion} />
              <VFlow>
                <StickyButton
                  showsAt={800}
                  size='small'
                  style={{ left: '90%' }}
                  onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
                >
                  <Icon icon='arrowUp' />
                  Voltar ao topo
                </StickyButton>
              </VFlow>
            </VFlow>
          </VFlow>
        </Cell>
      </Grid>
    </VFlow>
  )
}

const styles = {
  cellSideMenuNews: css`
    padding-left: 0 !important;
  `,
}
