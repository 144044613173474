import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const {
    ENVIO_RNDS_ENABLED,
    UPA_ENABLED,
    VISUALIZACAO_TERRITORIO_ENABLED,
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED,
    PILOTO_HISTORICO_CUIDADO_COMPARTILHADO_1_ENABLED,
    RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED,
  } = useFlags()
  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        {RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED && (
          <Text component='li'>Relatório gerencial de cuidado compartilhado</Text>
        )}
        {VISUALIZACAO_TERRITORIO_ENABLED && <Text component='li'>Acompanhamento do Território</Text>}
        <Text component='li'>Busca ativa de vacinação</Text>
        {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && <Text component='li'>Cuidado Compartilhado</Text>}
        {UPA_ENABLED && <Text component='li'>e-SUS APS Ampliado</Text>}
        <Text component='li'>Facilidades no agendamento de consultas de pré-natal</Text>
        <Text component='li'>Gerenciamento de cookies</Text>
        <Text component='li'>Gestão da garantia do acesso</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        {PILOTO_HISTORICO_CUIDADO_COMPARTILHADO_1_ENABLED && (
          <Text component='li'>Histórico do Cuidado Compartilhado</Text>
        )}
        <Text component='li'>Prescrição de medicamentos</Text>
        <Text component='li'>Prescrição digital</Text>
        {ENVIO_RNDS_ENABLED && <Text component='li'>RNDS - Envio de vacinas e atendimentos individuais </Text>}
        <Text component='li'>Videochamadas</Text>
        <Text component='li'>Unificação de cadastros</Text>
        <Text component='li'>Vínculo entre equipes do PEC</Text>
      </VFlow>
    </HFlow>
  )
}
