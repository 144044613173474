/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme } from 'bold-ui'
import { Agenda, AgendaProps, TimeInterval, Weekday } from 'components/agenda'
import { format, getDay, isBefore, startOfDay, subDays } from 'date-fns'
import { FechamentoAgenda } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { MutableRefObject, useCallback, useMemo } from 'react'

import { useAgendaAuthorization } from './authorization/useAgendaAuthorization'
import { AgendamentoCadastroView } from './cadastro-agendamento/AgendamentoCadastroView'
import { EventoAgendamento } from './components/listagem/EventoAgendamento'
import { HoverSlotDisponivel } from './components/listagem/HoverSlotDisponivel'
import { TimeSlotAgendaLotacao } from './components/listagem/TimeSlotAgendaLotacao'
import { convertAgendamentos, convertFechamento } from './converter-agenda'
import {
  AgendamentoQueryModel,
  AVAILABLE_TIMES_FECHAMENTO,
  EventoAgendaLotacao,
  LotacaoAgendaModel,
  TipoAgendamento,
} from './model-agenda'

export interface AgendaLotacaoProps
  extends Pick<AgendaProps<EventoAgendaLotacao>, 'date' | 'onNavigate' | 'stepInMinutes'> {
  lotacao: LotacaoAgendaModel
  availableTimes: Record<Weekday, TimeInterval[]>
  atencaoDomiciliar?: boolean
  isAgendaOnlineAtiva: boolean
  agendamentos: AgendamentoQueryModel[]
  reservasAgendaOnline: Map<Weekday, Map<string, boolean>>
  diasLiberacaoReservaAgendaOnline: number
  fechamentoDia: FechamentoAgenda
  formOpenRef: MutableRefObject<boolean>
  refetchInfos(): void
}

export function AgendaLotacao(props: AgendaLotacaoProps) {
  const {
    lotacao,
    atencaoDomiciliar: isAD = false,
    diasLiberacaoReservaAgendaOnline,
    stepInMinutes,
    isAgendaOnlineAtiva,
    reservasAgendaOnline,
    availableTimes,
    date,
    onNavigate,
    agendamentos,
    fechamentoDia,
    refetchInfos,
    formOpenRef,
  } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])
  const { getServerTimeNow } = useServerTime()

  const onFormClose = useCallback(() => {
    formOpenRef.current = false
    refetchInfos()
  }, [formOpenRef, refetchInfos])

  const onFormOpen = useCallback(() => (formOpenRef.current = true), [formOpenRef])

  const { hasAuthorizationAgendar } = useAgendaAuthorization(isAD)

  const hasPermissaoAgendar = lotacao && hasAuthorizationAgendar(lotacao)

  const isReservadoAgendaOnline = useCallback(
    (date: number) => {
      const day = getDay(date)
      const hour = format(date, 'HH:mm')
      const reservadoAgendaOnline = !!reservasAgendaOnline?.get(day)?.get(hour)

      return (
        reservadoAgendaOnline &&
        isBefore(startOfDay(getServerTimeNow()), subDays(startOfDay(date), diasLiberacaoReservaAgendaOnline))
      )
    },
    [diasLiberacaoReservaAgendaOnline, getServerTimeNow, reservasAgendaOnline]
  )

  const agendamentosDia = useMemo(() => agendamentos?.map((ag) => convertAgendamentos(ag, lotacao)), [
    agendamentos,
    lotacao,
  ])
  const events = useMemo(() => (fechamentoDia ? [convertFechamento(fechamentoDia, date)] : agendamentosDia), [
    agendamentosDia,
    date,
    fechamentoDia,
  ])

  const components = useMemo(
    () => ({
      AvailableSlotHover:
        hasPermissaoAgendar &&
        ((props) => (
          <HoverSlotDisponivel
            {...props}
            onPopperOpen={onFormOpen}
            onPopperClose={onFormClose}
            renderAgendamentoForm={(controls) => (
              <AgendamentoCadastroView
                getServerTimeNow={getServerTimeNow}
                lotacao={lotacao}
                isAtencaoDomiciliar={isAD}
                onClose={controls.close}
                start={props.start}
                end={props.end}
                defaultTab={isReservadoAgendaOnline(props.start) ? TipoAgendamento.RESERVA : TipoAgendamento.CONSULTA}
              />
            )}
          />
        )),
      Event: (props) => (
        <EventoAgendamento {...props} isAgendaOnlineAtiva={isAgendaOnlineAtiva} getServerTimeNow={getServerTimeNow} />
      ),
      TimeSlot: (props) => (
        <TimeSlotAgendaLotacao
          {...props}
          diasLiberacaoReservaAgendaOnline={diasLiberacaoReservaAgendaOnline}
          reservadoAgendaOnline={isReservadoAgendaOnline(props.start)}
        />
      ),
    }),
    [
      diasLiberacaoReservaAgendaOnline,
      getServerTimeNow,
      hasPermissaoAgendar,
      isAD,
      isAgendaOnlineAtiva,
      isReservadoAgendaOnline,
      lotacao,
      onFormClose,
      onFormOpen,
    ]
  )

  const renderForm = useMemo(
    () => (
      <div css={styles.container}>
        {!lotacao?.id ? (
          <Text component='p' fontWeight='bold' style={styles.textMessage}>
            Busque um profissional pelo seu nome, CNS, equipe ou CBO.
          </Text>
        ) : !availableTimes && !agendamentos?.length ? (
          <Text component='p' fontWeight='bold' style={styles.textMessage}>
            Lotação sem agenda configurada.
          </Text>
        ) : (
          <Agenda<EventoAgendaLotacao>
            date={date}
            events={events}
            getNow={getServerTimeNow}
            availableTimes={fechamentoDia ? AVAILABLE_TIMES_FECHAMENTO : availableTimes}
            stepInMinutes={stepInMinutes ?? 30}
            components={components}
            onNavigate={onNavigate}
          />
        )}
      </div>
    ),
    [
      agendamentos,
      availableTimes,
      components,
      date,
      events,
      fechamentoDia,
      getServerTimeNow,
      lotacao,
      onNavigate,
      stepInMinutes,
      styles.container,
      styles.textMessage,
    ]
  )

  return renderForm
}

const createStyles = (theme: Theme) => ({
  container: css`
    border-top: 1px solid ${theme.pallete.divider};
    margin-left: -0.5rem;
    margin-right: 0.25rem;
    margin-top: -0.5rem;
    height: 100%;
    overflow: auto;

    ${theme.breakpoints.up('lg')} {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  `,

  textMessage: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  `,
})
