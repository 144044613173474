/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeadingSection, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { AlterarSenhaForm } from 'components/form/alterar-senha/AlterarSenhaForm'
import { AlterarSenhaModel } from 'components/form/alterar-senha/model'
import { PageContent } from 'components/layout/PageContent'
import { useAlterarSenhaMutation } from 'graphql/hooks.generated'
import { useHistory } from 'react-router'

export interface AlterarSenhaProps {
  pedirSenhaAtual?: boolean
}

export const AlterarSenhaView = ({ pedirSenhaAtual = true }: AlterarSenhaProps) => {
  const [alterarSenha] = useAlterarSenhaMutation()
  const history = useHistory()
  const { data: session } = useSession()

  const handleSubmit = (values: AlterarSenhaModel) => {
    return alterarSenha({
      variables: {
        input: {
          senhaAtual: values.senhaAtual,
          novaSenhaInput: {
            novaSenha: values.novaSenhaInput.novaSenha,
            confirmacaoSenha: values.novaSenhaInput.confirmacaoSenha,
          },
        },
      },
    })
  }

  const handleCancelar = () => {
    session.acesso ? history.goBack() : (window.location.href = '/logout')
  }

  return (
    <PageContent type='filled' style={{ marginTop: '2rem' }}>
      <Breadcrumb title='Alterar senha do e-SUS APS PEC' />
      <VFlow>
        <HeadingSection
          title={pedirSenhaAtual ? 'Alterar senha do e-SUS APS PEC' : 'Definir senha do e-SUS APS PEC'}
          level={1}
        />
        <AlterarSenhaForm senhaAtualRequired={pedirSenhaAtual} onSubmit={handleSubmit} onCancelar={handleCancelar} />
      </VFlow>
    </PageContent>
  )
}

export const ForcarAlterarSenhaView = () => <AlterarSenhaView pedirSenhaAtual={false} />
