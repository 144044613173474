import EsusViewMountTarget from 'components/esus/EsusViewMountTarget'
import { MainLayout } from 'components/layout/MainLayout'
import {
  ACOMPANHAMENTO_CIDADAOS_VINCULADOS_PATH,
  ACOMPANHAMENTO_CONDICOES_SAUDE_PATH,
  ACOMPANHAMENTO_TERRITORIO_PATH,
  GESTAO_GARANTIA_ACESSO_PATH,
} from 'components/layout/SideMenu/SideMenuItems'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { Route } from 'react-router'
import { CBOS_ACESSO_CC, NIVEL_SUPERIOR } from 'types/NivelCbo'
import Permissions from 'types/Permissions'
import { SelecaoAcessoView } from 'view//login/SelecaoAcessoView/SelecaoAcessoView'
import { AlterarSenhaView } from 'view/alterar-senha/AlterarSenhaView'
import { CboListView } from 'view/cbo/list/CboListView'
import FichaAtendimentoOdontologicoView from 'view/cds//FichaAtendimentoOdontologicoView'
import FichaAtividadeColetivaView from 'view/cds//FichaAtividadeColetivaView'
import FichaCadastroDomiciliarView from 'view/cds//FichaCadastroDomiciliarView'
import FichaCadastroIndividualView from 'view/cds//FichaCadastroIndividualView'
import FichaComplementarView from 'view/cds//FichaComplementarView'
import FichaConsumoAlimentarView from 'view/cds//FichaConsumoAlimentarView'
import FichaElegibilidadeView from 'view/cds//FichaElegibilidadeView'
import FichaProcedimentosView from 'view/cds//FichaProcedimentosView'
import FichaVacinacaoView from 'view/cds//FichaVacinacaoView'
import FichaVisitaDomiciliarView from 'view/cds//FichaVisitaDomiciliarView'
import FichaAtendimentoDomiciliarView from 'view/cds/FichaAtendimentoDomiciliarView'
import FichaAtendimentoIndividualView from 'view/cds/FichaAtendimentoIndividualView'
import { GestaoMunicipalRootView } from 'view/configuracoes/municipal/GestaoMunicipalRootView'
import { ForbiddenView } from 'view/error/ForbiddenView'
import { NotFoundView } from 'view/error/NotFoundView'
import { EscolhaModuloAcessoRootView } from 'view/EscolhaModuloAcessoRootView'
import { Home } from 'view/Home'
import { LinksUteisView } from 'view/links-uteis/LinksUteisView'
import { PerfilRootView } from 'view/perfil/PerfilRootView'
import AtendimentoDomiciliarView from 'view/relatorio/AtendimentoDomiciliarView'
import AtendimentoIndividualView from 'view/relatorio/AtendimentoIndividualView'
import AtendimentoOdontologicoView from 'view/relatorio/AtendimentoOdontologicoView'
import AtividadeColetivaView from 'view/relatorio/AtividadeColetivaView'
import AvaliacaoElegibilidadeAdmissaoView from 'view/relatorio/AvaliacaoElegibilidadeAdmissaoView'
import CadastroDomiciliarTerritorialView from 'view/relatorio/CadastroDomiciliarTerritorialView'
import CadastroIndividualView from 'view/relatorio/CadastroIndividualView'
import ConfiguracaoImpressaoView from 'view/relatorio/ConfiguracaoImpressaoView'
import MarcadoresConsumoAlimentarView from 'view/relatorio/MarcadoresConsumoAlimentarView'
import ProcedimentosConsolidadosView from 'view/relatorio/ProcedimentosConsolidadosView'
import ProcedimentosIndividualizadosView from 'view/relatorio/ProcedimentosIndividualizadosView'
import ProcessamentoView from 'view/relatorio/ProcessamentoView'
import RelatorioCadastroTerritorialView from 'view/relatorio/RelatorioCadastroTerritorialView'
import RelatorioCriancaView from 'view/relatorio/RelatorioCriancaView'
import RelatorioGestantePuerperaView from 'view/relatorio/RelatorioGestantePuerperaView'
import RelatorioRiscoCardioView from 'view/relatorio/RelatorioRiscoCardioView'
import ResumoProducaoView from 'view/relatorio/ResumoProducaoView'
import SindromeNeurologicaZikaMicrocefaliaView from 'view/relatorio/SindromeNeurologicaZikaMicrocefaliaView'
import SituacaoTerritoriolView from 'view/relatorio/SituacaoTerritorioView'
import VacinacaoView from 'view/relatorio/VacinacaoView'
import VisitaDomiciliarTerritorialView from 'view/relatorio/VisitaDomiciliarTerritorialView'
import { SobreView } from 'view/sobre/SobreView'
import { TransmissaoRootView } from 'view/transmissao/TransmissaoRootView'
import { UnidadeSaudeRootView } from 'view/unidade-saude/UnidadeSaudeRootView'

import AcompanhamentoCidadaosVinculadosView from './acompanhamento-cidadaos-vinculados/AcompanhamentoCidadaosVinculadosView'
import AcompanhamentoCondicaoSaudeView from './acompanhamento-condicao-saude/AcompanhamentoCondicaoSaudeView'
import ConfiguracoesADView from './ad/configuracoes/ConfiguracoesADView'
import { ListaADRootView } from './ad/lista/ListaADRootView'
import { RelatorioADRootView } from './ad/relatorio/RelatorioADRootView'
import { AgendaRootView } from './agenda/AgendaRootView'
import { AtendimentoRootView } from './atendimentos/AtendimentoRootView'
import { AuditoriaView } from './auditoria/AuditoriaView'
import BuscaAtivaRootView from './busca-ativa/BuscaAtivaRootView'
import FichaCadastroDomiciliarDetailView from './cds/FichaCadastroDomiciliarDetailView'
import FichaCadastroIndividualDetailView from './cds/FichaCadastroIndividualDetailView'
import { CidadaoRootView } from './cidadao/CidadaoRootView'
import ImportarCidadaoView from './cidadao/ImportarCidadaoView'
import { visualizarCidadaoAndProntuarioPermissions } from './cidadao/types/CidadaoTabSectionModel'
import { ImportacaoCnesRootView } from './cnes/ImportacaoCnesRootView'
import { ConfiguracoesInstalacaoRootView } from './configuracoes/instalacao/ConfiguracoesInstalacaoRootView'
import { CuidadosCompartilhadosRootView } from './cuidados-compartilhados/CuidadosCompartilhadosRootView'
import { GarantiaAcessoRootView } from './lista-espera/GarantiaAcessoRootView'
import LoteImunobiologicoView from './lote-imunobiologico/LoteImunobiologicoView'
import { NewsModal } from './news/NewsModal'
import { NewsView } from './news/NewsView'
import { ProfissionalRootView } from './profissional/ProfissionalRootView'
import { REGISTRO_TARDIO_PATH, RegistroTardioRootView } from './registro-tardio/RegistroTardioRootView'
import { BiRootView } from './relatorio/bi/BiRootView'
import { ReterritorializacaoView } from './reterritorializacao/ReterritorializacaoView'
import { TermosView } from './termos-uso/TermosView'
import UnificacaoBaseCadastrosDuplicadosRootView from './unificacaoBaseCadastrosDuplicados/UnificacaoBaseCadastrosDuplicadosRootView'
import UnificacaoCadastroRootView from './unificacaoCadastro/UnificacaoCadastroRootView'
import { CriarVideochamadaView } from './videochamada/CriarVideochamadaView'
import { useVideochamadasConfiguration } from './videochamada/hooks/useVideochamadasConfiguration'
import { VisualizacaoTerritorialView } from './visualizacaoTerritorial/VisualizacaoTerritorialView'

export function LoggedRootView() {
  const { TERRITORIO_ENABLED, VISUALIZACAO_TERRITORIO_ENABLED, PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED } = useFlags()

  //TODO: @RNG Verificar novamente quando as configurações estiverem em um context (#14543)
  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()

  return (
    <MainLayout hasBreadcrumbs>
      <PecSwitch>
        <Route exact path='/' component={Home} />
        <Route path='/selecaoAcesso' component={SelecaoAcessoView} />
        <PrivateRoute
          path='/configuracoes/instalacao'
          component={ConfiguracoesInstalacaoRootView}
          permission={Permissions.configuracaoDeInstalacao}
        />
        <PrivateRoute
          path='/configuracoes/gestaomunicipal'
          component={GestaoMunicipalRootView}
          permission={Permissions.configuracaoMunicipal}
        />
        <PrivateRoute
          path='/profissional'
          component={ProfissionalRootView}
          permission={Permissions.visualizarProfissionais}
        />
        <PrivateRoute path='/perfil' component={PerfilRootView} permission={Permissions.visualizarPerfis} />
        <PrivateRoute path='/transmissao' component={TransmissaoRootView} permission={Permissions.transmissaoDeDados} />
        <PrivateRoute
          path='/unidadeSaude'
          component={UnidadeSaudeRootView}
          permission={Permissions.visualizarUnidadesDeSaude}
        />
        <PrivateRoute path='/importarCnes' component={ImportacaoCnesRootView} permission={Permissions.importarCnes} />
        <PrivateRoute path='/cbo' component={CboListView} permission={Permissions.visualizarCbo} />
        <PrivateRoute
          path='/cidadao'
          component={CidadaoRootView}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          path='/lista-atendimento'
          component={AtendimentoRootView}
          permission={Permissions.visualizarListaDeAtendimento}
        />
        <PrivateRoute
          exact
          path='/cds/ficha-complementar'
          component={FichaComplementarView}
          permission={Permissions.cds.fichaComplementarSindromeNeurologicaPorZikaOuMicrocefalia}
        />
        <PrivateRoute
          exact
          path='/cds/atendimento-domiciliar'
          component={FichaAtendimentoDomiciliarView}
          permission={Permissions.cds.fichaDeAtendimentoDomiciliar}
        />
        <PrivateRoute
          exact
          path='/cds/atendimento-individual'
          component={FichaAtendimentoIndividualView}
          permission={Permissions.cds.fichaDeAtendimentoIndividual}
        />
        <PrivateRoute
          exact
          path='/cds/atendimento-odontologico'
          component={FichaAtendimentoOdontologicoView}
          permission={Permissions.cds.fichaDeAtendimentoOdontologico}
        />
        <PrivateRoute
          exact
          path='/cds/atividade-coletiva'
          component={FichaAtividadeColetivaView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
        <PrivateRoute
          exact
          path='/cds/avaliacao-elegibilidade-admissao'
          component={FichaElegibilidadeView}
          permission={Permissions.cds.fichaDeAvaliacaoDeElegibilidade}
        />
        <PrivateRoute
          exact
          path='/cds/cadastro-domiciliar'
          component={FichaCadastroDomiciliarView}
          permission={Permissions.cds.fichaDeCadastroDomiciliar}
        />
        {VISUALIZACAO_TERRITORIO_ENABLED && (
          <PrivateRoute
            exact
            path='/cds/cadastro-domiciliar/detail'
            component={FichaCadastroDomiciliarDetailView}
            permission={Permissions.cds.fichaDeCadastroDomiciliar}
          />
        )}
        <PrivateRoute
          exact
          path='/cds/cadastro-individual'
          component={FichaCadastroIndividualView}
          permission={Permissions.cds.fichaDeCadastroIndividual}
        />
        {VISUALIZACAO_TERRITORIO_ENABLED && (
          <PrivateRoute
            exact
            path='/cds/cadastro-individual/detail'
            component={FichaCadastroIndividualDetailView}
            permission={Permissions.cds.fichaDeCadastroIndividual}
          />
        )}
        <PrivateRoute
          exact
          path='/cds/consumo-alimentar'
          component={FichaConsumoAlimentarView}
          permission={Permissions.cds.fichaDeConsumoAlimentar}
        />
        <PrivateRoute
          exact
          path='/cds/procedimentos'
          component={FichaProcedimentosView}
          permission={Permissions.cds.fichaDeProcedimentos}
        />
        <PrivateRoute
          exact
          path='/cds/ficha-vacinacao'
          component={FichaVacinacaoView}
          permission={Permissions.cds.fichaDeVacinacao}
        />
        <PrivateRoute
          exact
          path='/cds/visita-domiciliar'
          component={FichaVisitaDomiciliarView}
          permission={Permissions.cds.fichaDeVisitaDomiciliar}
        />
        <PrivateRoute path='/agenda' component={AgendaRootView} permission={Permissions.visualizarAgenda} />
        <PrivateRoute
          path={ACOMPANHAMENTO_CONDICOES_SAUDE_PATH}
          component={AcompanhamentoCondicaoSaudeView}
          permission={Permissions.acompanhamentos.condicoesDeSaude}
        />
        <PrivateRoute
          path={ACOMPANHAMENTO_CIDADAOS_VINCULADOS_PATH}
          component={AcompanhamentoCidadaosVinculadosView}
          permission={Permissions.acompanhamentos.cidadaosVinculados}
        />
        <PrivateRoute
          exact
          path='/relatorios/consolidados/cadastro-domiciliar'
          component={CadastroDomiciliarTerritorialView}
          permission={Permissions.relatorios.consolidados.cadastroDomiciliarETerritorial}
        />
        <PrivateRoute
          exact
          path='/relatorios/consolidados/cadastro-individual'
          component={CadastroIndividualView}
          permission={Permissions.relatorios.consolidados.cadastroIndividual}
        />
        <PrivateRoute
          exact
          path='/relatorios/consolidados/situacao-territorio'
          component={SituacaoTerritoriolView}
          permission={Permissions.relatorios.consolidados.situacaoDoTerritorio}
        />
        <PrivateRoute
          exact
          path='/relatorios/operacionais/cadastro-territorial'
          component={RelatorioCadastroTerritorialView}
          permission={Permissions.relatorios.operacional.cadastroDoTerritorio}
        />
        <PrivateRoute
          exact
          path='/relatorios/operacionais/gestante-puerpera'
          component={RelatorioGestantePuerperaView}
          permission={Permissions.relatorios.operacional.gestante}
        />
        <PrivateRoute
          exact
          path='/relatorios/operacionais/crianca'
          component={RelatorioCriancaView}
          permission={Permissions.relatorios.operacional.crianca}
        />
        <PrivateRoute
          exact
          path='/relatorios/operacionais/risco-cardiovascular'
          component={RelatorioRiscoCardioView}
          permission={Permissions.relatorios.operacional.riscoCardiovascular}
        />
        <PrivateRoute
          path='/relatorios/producao/atendimento-domiciliar'
          component={AtendimentoDomiciliarView}
          permission={Permissions.relatorios.producao.atendimentoDomiciliar}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/atendimento-individual'
          component={AtendimentoIndividualView}
          permission={Permissions.relatorios.producao.atendimentoIndividual}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/atendimento-odontologico'
          component={AtendimentoOdontologicoView}
          permission={Permissions.relatorios.producao.atendimentoOdontologicoIndividual}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/atividade-coletiva'
          component={AtividadeColetivaView}
          permission={Permissions.relatorios.producao.atividadeColetiva}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/avaliacao-elegibilidade-admissao'
          component={AvaliacaoElegibilidadeAdmissaoView}
          permission={Permissions.relatorios.producao.avaliacaoDeElegibilidadeEAdmissao}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/marcadores-consumo-alimentar'
          component={MarcadoresConsumoAlimentarView}
          permission={Permissions.relatorios.producao.marcadoresDeConsumoAlimentar}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/procedimentos-individualizados'
          component={ProcedimentosIndividualizadosView}
          permission={Permissions.relatorios.producao.procedimentos}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/procedimentos-consolidados'
          component={ProcedimentosConsolidadosView}
          permission={Permissions.relatorios.producao.procedimentosConsolidados}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/resumo-producao'
          component={ResumoProducaoView}
          permission={Permissions.relatorios.producao.resumoDeProducao}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/sindrome-neurologica-zika'
          component={SindromeNeurologicaZikaMicrocefaliaView}
          permission={Permissions.relatorios.producao.sindromeNeurologicaPorZikaOuMicrocefalia}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/vacinacao'
          component={VacinacaoView}
          permission={Permissions.relatorios.producao.vacinacao}
        />
        <PrivateRoute
          exact
          path='/relatorios/producao/visita-domiciliar'
          component={VisitaDomiciliarTerritorialView}
          permission={Permissions.relatorios.producao.visitaDomiciliar}
        />
        <PrivateRoute
          exact
          path='/relatorios/processamento'
          component={ProcessamentoView}
          permission={Permissions.relatorios.processarRelatorios}
        />
        <PrivateRoute
          exact
          path='/relatorios/configuracao-impressao'
          component={ConfiguracaoImpressaoView}
          permission={Permissions.relatorios.processarRelatorios}
        />
        <PrivateRoute path='/relatorios/bi' component={BiRootView} permission={Permissions.relatorios} />
        <PrivateRoute
          exact
          path='/importarCidadao'
          component={ImportarCidadaoView}
          permission={Permissions.importarCadastrosDeCidadao}
        />
        <PrivateRoute
          path='/ad/agenda'
          render={(routeProps) => <AgendaRootView atencaoDomiciliar {...routeProps} />}
          permission={Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe}
        />
        <PrivateRoute
          path='/ad/lista'
          component={ListaADRootView}
          permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe}
        />
        <PrivateRoute
          path='/ad/relatorio'
          component={RelatorioADRootView}
          permission={Permissions.atencaoDomiciliar.gerarRelatorioDeAdDaEquipe}
        />
        <PrivateRoute
          path='/gestaoCadastros/unificacaoCadastro'
          component={UnificacaoCadastroRootView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeCadastros}
        />
        <PrivateRoute
          path='/gestaoCadastros/unificacaoBaseCadastrosDuplicados'
          component={UnificacaoBaseCadastrosDuplicadosRootView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeBases}
        />
        <PrivateRoute
          path={REGISTRO_TARDIO_PATH}
          component={RegistroTardioRootView}
          permission={Permissions.visualizarListaDeAtendimento}
        />
        <PrivateRoute
          path='/busca-ativa'
          component={BuscaAtivaRootView}
          permission={Permissions.buscaAtivaDeVacinacao}
        />
        <PrivateRoute
          path='/lotesImunobiologico'
          component={LoteImunobiologicoView}
          permission={Permissions.cadastrarEditarEExcluirLotesDeImunobiologico}
        />
        <PrivateRoute
          path='/configuracoesAd'
          component={ConfiguracoesADView}
          permission={Permissions.configurarAtencaoDomiciliar}
        />
        <PrivateRoute path='/auditoria' component={AuditoriaView} permission={Permissions.gerarTrilhaDeAuditoria} />
        {TERRITORIO_ENABLED && (
          <PrivateRoute
            path='/gestaoCadastros/reterritorializacao'
            component={ReterritorializacaoView}
            permission={Permissions.gestaoDeCadastrosDeCidadao.reterritorializacao}
          />
        )}
        {VISUALIZACAO_TERRITORIO_ENABLED && (
          <PrivateRoute
            path={ACOMPANHAMENTO_TERRITORIO_PATH}
            component={VisualizacaoTerritorialView}
            permission={Permissions.acompanhamentos.territorio}
          />
        )}
        {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
          <PrivateRoute
            path='/videochamada'
            component={CriarVideochamadaView}
            permission={Permissions.videochamada.realizarVideochamada}
            cbos={NIVEL_SUPERIOR}
            acceptEstagio={false}
          />
        )}
        <Route path='/unificacaoCadastro' component={UnificacaoCadastroRootView} />
        <Route path='/lotesImunobiologico' component={LoteImunobiologicoView} />
        <Route path='/configuracoesAd' component={ConfiguracoesADView} />
        <Route path='/linksUteis' component={LinksUteisView} />
        <Route path='/sobre' component={SobreView} />
        <Route path='/alterarSenha' component={AlterarSenhaView} />
        <Route path='/escolhaModulo' component={EscolhaModuloAcessoRootView} />
        <Route path='/termosUso' component={TermosView} />
        <Route path='/novidades' component={NewsView} />
        <PrivateRoute path='/auditoria' component={AuditoriaView} permission={Permissions.gerarTrilhaDeAuditoria} />
        <Route path='/403' component={ForbiddenView} />
        <Route path='/404' component={NotFoundView} />
        <Route path={GESTAO_GARANTIA_ACESSO_PATH} component={GarantiaAcessoRootView} />
        {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
          <PrivateRoute
            path='/gestao-filas/cuidados-compartilhados'
            component={CuidadosCompartilhadosRootView}
            tiposEstabelecimento={[TipoEstabelecimentoEnum.UBS]}
            cbos={CBOS_ACESSO_CC}
          />
        )}
      </PecSwitch>
      <NewsModal />
      <EsusViewMountTarget />
    </MainLayout>
  )
}
