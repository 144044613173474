import { useFirebase } from 'hooks/firebase/useFirebase'
import { useLocalStorage } from 'hooks/useStorage'
import { noop } from 'lodash'
import React, { useCallback, useContext, useMemo } from 'react'

import { CookieBanner } from '../components/cookie/CookieBanner'

const COOKIE_KEY = 'COOKIE_CONTROL'

interface CookieConfigModel {
  cookiesAccepted: boolean
}

interface CookiesAuthorizationProviderProps {
  children: React.ReactNode
}

interface CookieConfigContext {
  cookiesEnabled: boolean
  denyCookies: () => void
}

const CookieContext = React.createContext<CookieConfigContext>({
  cookiesEnabled: false,
  denyCookies: noop,
})

export function CookiesAuthorizationProvider({ children }: CookiesAuthorizationProviderProps) {
  const { initializeFirebase, disableFirebase } = useFirebase()
  const [cookieConfig, setCookieConfig, clearCookieConfig] = useLocalStorage<CookieConfigModel>(COOKIE_KEY)

  const handleClickAceitarTodos = useCallback(() => {
    setCookieConfig({ cookiesAccepted: true })
    initializeFirebase()
  }, [initializeFirebase, setCookieConfig])

  const handleClearCookieConfig = useCallback(() => {
    clearCookieConfig(true)
    disableFirebase()
  }, [clearCookieConfig, disableFirebase])

  const handleClickAceitarNecessarios = useCallback(() => {
    handleClearCookieConfig()
    setCookieConfig({ cookiesAccepted: true })
  }, [handleClearCookieConfig, setCookieConfig])

  const valueCookieConfig: CookieConfigContext = useMemo(
    () => ({
      cookiesEnabled: cookieConfig?.cookiesAccepted,
      denyCookies: handleClearCookieConfig,
    }),
    [cookieConfig, handleClearCookieConfig]
  )

  return (
    <CookieContext.Provider value={valueCookieConfig}>
      {!cookieConfig && (
        <CookieBanner
          onClickAceitarTodos={handleClickAceitarTodos}
          onClickAceitarNecessarios={handleClickAceitarNecessarios}
        />
      )}
      {children}
    </CookieContext.Provider>
  )
}

export const useCookieConfig = () => useContext(CookieContext)
