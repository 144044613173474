/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, ButtonGroup, Cell, Grid, Heading, HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  CheckboxField,
  DateField,
  ErrorField,
  HorarioAgendaSelectField,
  TelefoneField,
  TextAreaField,
  TextField,
} from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { InfoIcon } from 'components/InfoIcon'
import { Telefone } from 'components/label'
import { useFlags } from 'config/useFlagsContext'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { useServerTime } from 'hooks/useServerTime'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { InserirVideochamadaAgendamentoSection } from 'view/agenda/components/InserirVideochamadaAgendamentoSection'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface AgendarConsultaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const AgendarConsultaCuidadoCompartilhadoField = (props: AgendarConsultaCuidadoCompartilhadoFieldProps) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props

  const name = path.agendamento
  const { getServerTimeNow } = useServerTime()
  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()
  const serverTime = getServerTimeNow()
  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = videochamadaUuidState
  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED, PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()

  const {
    input: { value },
  } = usePecField({ name, subscription: { value: true } })

  const {
    input: { value: tipoAgendamentoValue, onChange: onChangeTipoAgendamento },
  } = usePecField({ name: name.tipoAgendamento, subscription: { value: true } })

  const isConsultaCidadaoPresencial = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO
  const isConsultaCompartilhada = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA

  const { lotacaoConvidada } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(lotacao.id, cuidadoCompartilhado)

  const cidadaoHasTelefone = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.telefoneCelular)
  const cidadaoHasEmail = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.email)

  const isCheckedCidadaoPresenteConsultaCompartilhada = !!value?.consultaCompartilhada?.isCidadaoPresente

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<AgendarConsultaCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.AGENDAR_CONSULTA}
    >
      <VFlow>
        {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
          <ButtonGroup>
            <Button
              kind={isConsultaCidadaoPresencial ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO)}
            >
              Consulta com o cidadão
            </Button>
            <Button
              kind={isConsultaCompartilhada ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA)}
            >
              Consulta entre profissionais
            </Button>
          </ButtonGroup>
        ) : (
          <Heading level={3}>Consulta com o cidadão</Heading>
        )}

        {isConsultaCidadaoPresencial && (
          <VFlow>
            <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            <Grid>
              <Cell size={6} lg={3}>
                <DateField
                  name={name.consultaComCidadao.data}
                  label='Data do agendamento'
                  minDate={serverTime}
                  maxDate={addMonths(serverTime, 12)}
                  clearable
                  required
                />
              </Cell>
              <Cell size={6} lg={3}>
                <HorarioAgendaSelectField
                  name={name.consultaComCidadao.horario}
                  lotacaoId={lotacao.id}
                  dia={value.consultaComCidadao?.data ? parseISO(value.consultaComCidadao?.data) : undefined}
                  disabled={!value.consultaComCidadao?.data}
                  labels={{ inicial: 'Horário do agendamento' }}
                  clearable
                  required
                />
              </Cell>
              <Cell size={12}>
                <TextAreaField
                  name={name.consultaComCidadao.observacoes}
                  label='Observações'
                  placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
                  maxLength={200}
                  style={styles.textArea}
                />
              </Cell>
            </Grid>
          </VFlow>
        )}

        {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && isConsultaCompartilhada && (
          <VFlow>
            <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            <Grid>
              <Cell size={6} lg={3}>
                <DateField
                  name={name.consultaCompartilhada.data}
                  label='Data do agendamento'
                  minDate={serverTime}
                  maxDate={addMonths(serverTime, 12)}
                  clearable
                  required
                />
              </Cell>

              <Cell size={6} lg={3}>
                <HorarioAgendaSelectField
                  name={name.consultaCompartilhada.horario}
                  lotacaoId={lotacao.id}
                  outraLotacaoIdVerificarDisponibilidade={lotacaoConvidada?.id}
                  dia={value.consultaCompartilhada?.data ? parseISO(value.consultaCompartilhada.data) : undefined}
                  disabled={!value.consultaCompartilhada.data}
                  labels={{ inicial: 'Horário do agendamento' }}
                  required
                />
              </Cell>

              {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
                <Cell size={6}>
                  <InserirVideochamadaAgendamentoSection
                    videochamadaUuid={videochamadaUuid}
                    onVideochamadaUuidChange={setVideochamadaUuid}
                    onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                  />
                </Cell>
              )}

              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <HFlow alignItems='center' hSpacing={0.25}>
                    <Text fontSize={0.875} fontWeight='bold'>
                      Dados do profissional convidado
                    </Text>
                    <InfoIcon
                      icon='infoCircleFilled'
                      text='Os dados de contato adicionados valerão  apenas para o agendamento, sem edição no cadastro do profissional.'
                    />
                  </HFlow>
                  <Text>
                    {lotacaoConvidada.profissional?.nome} | {lotacaoConvidada.cbo?.nome.capitalize()} |{' '}
                    {lotacaoConvidada.equipe?.nome}
                  </Text>
                </VFlow>
              </Cell>

              <Cell size={12}>
                <InfoLabel title='E-mail'>{lotacaoConvidada?.profissional.email ?? '-'}</InfoLabel>
              </Cell>

              <Cell size={12}>
                <VFlow vSpacing={0.5}>
                  <Text fontSize={0.875} fontWeight='bold'>
                    Dados do cidadão
                  </Text>

                  <VFlow vSpacing={1}>
                    {!cuidadoCompartilhado.cidadaoAceitaAtendTic && (
                      <Alert type='info'>
                        O cidadão não concordou com agendamento da consulta com o especialista mediada por tecnologia
                        durante o atendimento em que houve a solicitação de cuidado compartilhado.
                      </Alert>
                    )}

                    <CheckboxField
                      name={name.consultaCompartilhada.isCidadaoPresente}
                      label={<Text fontWeight='bold'>Cidadão estará presente na consulta compartilhada</Text>}
                      style={styles.checkbox}
                    />
                  </VFlow>
                </VFlow>
              </Cell>

              <Cell size={6} lg={cidadaoHasEmail ? 3 : 6}>
                {cidadaoHasEmail ? (
                  <InfoLabel title='E-mail do cidadão'>{cuidadoCompartilhado.cidadao.email}</InfoLabel>
                ) : (
                  <TextField
                    lowercase
                    name={name.consultaCompartilhada.cidadaoParticipante.contato.email}
                    label='E-mail do cidadão'
                    maxLength={50}
                    disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
                  />
                )}
              </Cell>

              <Cell size={6}>
                {cidadaoHasTelefone ? (
                  <InfoLabel title='Telefone celular do cidadão'>
                    <Telefone value={cuidadoCompartilhado.cidadao.telefoneCelular} />
                  </InfoLabel>
                ) : (
                  <TelefoneField
                    name={name.consultaCompartilhada.cidadaoParticipante.contato.telefoneCelular}
                    label='Telefone celular do cidadão'
                    disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
                  />
                )}
              </Cell>

              <Cell size={12}>
                <TextAreaField
                  label='Observações'
                  name={name.consultaCompartilhada.observacoes}
                  placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
                  maxLength={200}
                  style={styles.textArea}
                />
              </Cell>
            </Grid>
          </VFlow>
        )}
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

const styles = {
  checkbox: css`
    margin-left: -0.25rem;
  `,
  textArea: css`
    resize: vertical;
  `,
}
