import { Cell, FormControl, Grid, HFlow, Icon, isEmpty, Tooltip, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { CboSelectField, ErrorField, RichTextField } from 'components/form'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import theme from 'config/theme'
import { css } from 'emotion'
import { Cbo, CiapCapituloEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { useField } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { ClassificacaoPrioridadeField } from '../components/ClassificacaoPrioridadeField'
import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

const path = metaPath<CuidadoCompartilhadoPlanoModel>()

export const CuidadoCompartilhadoFormFields = () => {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const {
    cidadao,
    permissoes: { somenteCiap },
  } = useAtendimentoContext()

  const isIdentidadeGeneroEmpty = isEmpty(cidadao?.identidadeGeneroDbEnum)
  const unidadeSaudeId = acesso.unidadeSaude.id
  const equipeId = acesso.equipe?.id
  const cidadaoSexo = isIdentidadeGeneroEmpty ? cidadao?.sexo : null
  const {
    input: { value: cbo },
  } = useField<Cbo>(path.cbo.absolutePath(), { subscription: { value: true } })

  return (
    <Grid>
      <Cell size={6}>
        <CboSelectField
          name={path.cbo}
          label='CBO'
          mostrarIndisponivelLotacao={false}
          apenasEquipeCuidadoCompartilhado
          equipeVinculadaId={equipeId}
          nivelSuperior={true}
          excludeProfissionaisIds={[profissional.id]}
        />
      </Cell>
      <Cell size={6}>
        <FormControl label='Profissional' required>
          <HFlow hSpacing={0.5} alignItems='baseline' style={styles.hFlow}>
            <LotacaoAndEstagioSelectField
              name={path.lotacao}
              unidadeSaudeId={unidadeSaudeId}
              cboId={cbo?.id}
              canWriteAtendimento={false}
              nivelSuperior
              excludeProfissionaisIds={[profissional.id]}
              apenasEquipeCuidadoCompartilhado
              equipeVinculadaId={equipeId}
              required
            />
            <Tooltip
              text='O campo só traz profissionais de equipes NASF e eMulti vinculados a essa unidade.'
              placement='top'
            >
              <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
            </Tooltip>
          </HFlow>
        </FormControl>
      </Cell>
      <Cell size={12}>
        <VFlow vSpacing={0.5}>
          <FormControl label='Problemas/condições' required>
            <Grid>
              <Cell size={6}>
                <CiapSelectField
                  name={path.problemasECondicoes.ciap}
                  label='CIAP 2'
                  idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                  sexo={isEmpty(cidadao.identidadeGeneroDbEnum) ? cidadaoSexo : undefined}
                  capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
                  excluirCIAPsDAB
                  menuMinWidth={620}
                />
              </Cell>
              {!somenteCiap && (
                <Cell size={6}>
                  <CidSelectField
                    name={path.problemasECondicoes.cid10}
                    label='CID 10'
                    renderGrupoCiapRelacionada={false}
                    idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                    sexo={cidadaoSexo}
                  />
                </Cell>
              )}
            </Grid>
          </FormControl>
          <ErrorField name={path.problemasECondicoes} ignoreObjectError />
        </VFlow>
      </Cell>
      <Cell size={12}>
        <ClassificacaoPrioridadeField name={path.prioridade} required />
      </Cell>
      <Cell size={12}>
        <RichTextField
          name={path.discussao}
          label='Discussão de caso clínico'
          placeholder='Insira objetivamente o relato do caso, além de dúvidas e questionamentos'
          maxLength={1000}
          required
        />
      </Cell>
      <Cell size={12}>
        <FormControl
          label='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediada por tecnologia?'
          required
        >
          <HFlow>
            <SimNaoRadioGroupField name={path.cidadaoAceitaAtendTic} />
          </HFlow>
        </FormControl>
      </Cell>
    </Grid>
  )
}

const styles = {
  hFlow: css`
    grid-auto-columns: 1fr auto;
  `,
  iconTooltip: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
}
