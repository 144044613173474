import { blue, ColorScale, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { CboSelectModel } from 'components/form'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { Color } from 'csstype'
import {
  Cbo,
  ClassificacaoPrioridadeCuidadoEnum,
  CuidadosCompartilhadoPlanoQuery,
  Equipe,
  LotacacaoCuidadoCompartilhadoSelectFieldQuery,
  Profissional,
  UnidadeSaude,
  UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
} from 'graphql/types.generated'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

export const EDITAR_CUIDADO_COMPARTILHADO_URL = '/compartilhamento-de-cuidado/editar'

export interface CuidadoCompartilhadoPlanoModel extends RowType {
  cbo: CboSelectModel
  lotacao: LotacaoAndEstagioSelectFieldModel
  prioridade: ClassificacaoPrioridadeCuidadoEnum
  discussao: string
  problemasECondicoes: {
    ciap?: CiapSelectFieldModel
    cid10?: CidSelectFieldModel
  }
  cidadaoAceitaAtendTic: SimNaoEnum
}

export type CuidadoCompartilhadoPlanoQueryModel = CuidadosCompartilhadoPlanoQuery['cuidadosCompartilhado']['content'][0]

export type LotacaoCuidadaoCompartilhadoSelectFieldModel = {
  id: ID
  cbo: Cbo
  equipe: Pick<Equipe, 'id' | 'nome' | 'ine'>
  profissional: Pick<Profissional, 'id' | 'nome' | 'cpf'>
  unidadeSaude: Pick<UnidadeSaude, 'id' | 'nome' | 'cnes'>
}

export type LotacaoCuidadoCompartilhadoSelectFieldQueryModel = LotacacaoCuidadoCompartilhadoSelectFieldQuery['lotacoesByCuidadoCompartilhado'][0]

export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel = UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery['estabelecimentosByCuidadoCompartilhado'][0]

export interface CuidadoCompartilhadoPlanoTableItemModel {
  id: ID
  dataInicioAtendimento: Instant
  prioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
  cidadaoAceitaAtendTic: boolean
  discussao: string
  lotacaoExecutanteAtual: CuidadoCompartilhadoPlanoQueryModel['lotacaoExecutanteAtual']
  lotacaoSolicitante: CuidadoCompartilhadoPlanoQueryModel['lotacaoSolicitante']
  isRegistradoAgora: boolean
  cid10?: CidSelectFieldModel
  ciap?: CiapSelectFieldModel
  cacheId?: string
}

export const cuidadoCompartilhadoClassificacaoPrioridadeRecord: Record<
  ClassificacaoPrioridadeCuidadoEnum,
  { label: string; mainColor: Color; colorScale: ColorScale }
> = {
  [ClassificacaoPrioridadeCuidadoEnum.BAIXA]: {
    label: 'Baixa',
    mainColor: blue.c40,
    colorScale: blue,
  },
  [ClassificacaoPrioridadeCuidadoEnum.MEDIA]: {
    label: 'Média',
    mainColor: green.c40,
    colorScale: green,
  },
  [ClassificacaoPrioridadeCuidadoEnum.ALTA]: {
    label: 'Alta',
    mainColor: yellow.c60,
    colorScale: yellow,
  },
  [ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA]: {
    label: 'Muito alta',
    mainColor: red.c40,
    colorScale: red,
  },
}
