import { Cell, FormControl, Grid, HFlow, Text, VFlow } from 'bold-ui'
import { AccordionInformation } from 'components/accordion/AccordionInformation'
import { RadioField } from 'components/form/final-form/RadioField/RadioField'
import { RichTextField } from 'components/form/final-form/RichTextField/RichTextField'
import { useFlags } from 'config/useFlagsContext'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  DevolutivaCuidadoCompartilhadoFieldsModel,
  DiscussaoCasoExecutanteFieldsModel,
  LabelEvolucaoCuidadoCompartilhadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface DevolutivaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
}

export const DevolutivaCuidadoCompartilhadoField = (props: DevolutivaCuidadoCompartilhadoFieldProps) => {
  const { path } = props

  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED } = useFlags()

  const name = path.devolutiva

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<DevolutivaCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO}
    >
      <VFlow>
        <Text>
          Responda a pergunta relacionada ao relato de caso, caso faltem informações solicite aqui mais informações para
          o profissional solicitante.
        </Text>
        <Grid>
          <Cell size={10}>
            <AccordionInformation type='normal' uuid='discussaoCaso-info' header='Como preencher o Tipo de devolutiva'>
              <VFlow vSpacing={0.2}>
                <Text>
                  <strong>Pedido de esclarecimento:</strong> Use esta opção se surgirem dúvidas ou faltarem informações,
                  solicitando esclarecimentos adicionais sobre o caso.
                </Text>
                <Text>
                  <strong>Teleconsultoria:</strong> Use esta opção para compartilhar perspectivas iniciais e
                  conhecimento de caso.
                </Text>
                {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
                  <Text>
                    <strong>Recondução de cuidado:</strong> Use esta opção caso não esteja dentro do escopo do cuidado
                    compartilhado.
                  </Text>
                )}
              </VFlow>
            </AccordionInformation>
          </Cell>
        </Grid>
        <FormControl label='Tipo de devolutiva' required>
          <HFlow>
            <VFlow vSpacing={0.5}>
              <RadioField
                label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO]}
                name={name.conduta}
                value={CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO}
              />
            </VFlow>
            <VFlow vSpacing={0.5}>
              <RadioField
                label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA]}
                name={name.conduta}
                value={CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA}
              />{' '}
            </VFlow>
            <VFlow vSpacing={0.5}>
              {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
                <RadioField
                  label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO]}
                  name={name.conduta}
                  value={CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO}
                />
              )}
            </VFlow>
          </HFlow>
        </FormControl>
        <RichTextField
          name={name.resposta}
          label='Observações'
          placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
          maxLength={1000}
          required
        />
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}
