/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { EquipeSelectField, ErrorField, Form, FormRenderProps, SubmitButton, TextAreaField } from 'components/form'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { GESTAO_GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { FORM_ERROR, FormApi } from 'final-form'
import { useEditarCidadaoGarantiaAcessoMutation, useGarantiaAcessoQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useHistory, useParams } from 'react-router'
import { formatDate } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { resolveEquipeTooltip } from 'view/atendimentos/tooltipResolver'

import { tipoAtendimentoRecord, TipoAtendimentoSelectField } from '../components/TipoAtendimentoSelectField'
import { convertGarantiaAcessoEdicaoModelToInput } from '../convert-garantiaAcesso'
import { useGestaoGarantiaAcessoConflitantes } from '../hooks/useGestaoGarantiaAcessoConflitantes'
import { GarantiaAcessoEdicaoModel } from '../model-garantiaAcesso'
import { editarCidadaoGarantiaAcessoValidator } from '../validator-garantiaAcesso'

const name = metaPath<GarantiaAcessoEdicaoModel>()

interface UrlParams {
  garantiaAcessoId: ID
}

export default function GarantiaAcessoEditarCidadaoModalForm() {
  const { garantiaAcessoId } = useParams<UrlParams>()
  const history = useHistory()
  const alert = useAlert()
  const {
    acesso: { unidadeSaude },
    tipoEstabelecimento,
  } = useAcessoLotacaoOrEstagio()
  const [update] = useEditarCidadaoGarantiaAcessoMutation()
  const { verificarRegistrosConflitantes } = useGestaoGarantiaAcessoConflitantes()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  const {
    data: { garantiaAcessoItemById: garantiaAcesso },
  } = useGarantiaAcessoQuery({
    variables: {
      input: garantiaAcessoId,
    },
  })

  if (!garantiaAcesso?.cidadao) {
    return null
  }
  const initialValues: Required<GarantiaAcessoEdicaoModel> = {
    garantiaAcessoId: garantiaAcesso.id,
    motivo: garantiaAcesso.motivoEspera,
    tipoAtendimento: tipoAtendimentoRecord[garantiaAcesso.tipoAtendimento],
    tipoServico: garantiaAcesso.tipoServico,
    equipe: garantiaAcesso.equipe,
  }

  const goBack = () => history.push(GESTAO_GARANTIA_ACESSO_PATH)

  const handleSubmit = (values: GarantiaAcessoEdicaoModel, formApi: FormApi<GarantiaAcessoEdicaoModel>) => {
    return verificarRegistrosConflitantes(unidadeSaude.id, garantiaAcesso.cidadao, isCEO, values, true).then(
      (confirmed) => confirmed && updateGarantiaAcesso(values)
    )
  }

  const updateGarantiaAcesso = (values: GarantiaAcessoEdicaoModel) => {
    const valuesInput = { ...values, garantiaAcessoId }
    return update({ variables: { input: { ...convertGarantiaAcessoEdicaoModelToInput(valuesInput) } } }).then(() => {
      goBack()
      alert('success', 'Registro atualizado com sucesso.')
    })
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoEdicaoModel>) => {
    const { cidadao, dataEntrada } = garantiaAcesso
    const { values } = formProps

    return (
      <Modal onClose={goBack} open size='small'>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Editar registro da lista</Heading>
            <ErrorField name={FORM_ERROR} type='alert' />
            <Grid>
              <Cell size={12}>
                <Heading level={3}>
                  {(cidadao.nomeSocial ?? cidadao.nome).titleCase()}
                  <Text style={styles.fontWeight}>{` | Registrado em ${formatDate(dataEntrada)}`}</Text>
                </Heading>
                <VFlow vSpacing={0}>
                  <HFlow>
                    <HLabel title='CPF'>
                      <Cpf value={cidadao.cpf} />
                    </HLabel>
                    <HLabel title='CNS'>{cidadao.cns}</HLabel>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow>
                  {!isCEO && (
                    <Tooltip text={resolveEquipeTooltip(values)}>
                      <EquipeSelectField name={name.equipe} label='Equipe' unidadeSaudeId={unidadeSaude.id} />
                    </Tooltip>
                  )}

                  {isCEO ? (
                    <TipoServicoSelectField
                      name={name.tipoServico}
                      label='Tipo de serviço'
                      title='Tipo de serviço'
                      required
                    />
                  ) : (
                    <TipoAtendimentoSelectField
                      name={name.tipoAtendimento}
                      label='Tipo de atendimento'
                      title='Tipo de atendimento'
                      required
                    />
                  )}

                  <TextAreaField
                    name={name.motivo}
                    label='Motivo da consulta'
                    title='Motivo da consulta'
                    maxLength={200}
                    required
                    style={css`
                      height: 5rem;
                      resize: none;
                    `}
                  />
                </VFlow>
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack} title='Cancelar' size='medium' style={styles.button}>
              Cancelar
            </Button>
            <SubmitButton
              title='Salvar'
              size='medium'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              style={styles.button}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<GarantiaAcessoEdicaoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      suppressNotificationError
      validate={editarCidadaoGarantiaAcessoValidator(isCEO)}
      initialValues={initialValues}
    />
  )
}

const styles = {
  button: css`
    height: 3rem;
    width: 9rem;
  `,
  fontWeight: css`
    font-weight: normal;
  `,
}
