import { Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { HistoricoAtendimentoModel } from 'view/atendimentos/detail/historico/model/model-historico'
import { lotacaoCuidadoCompartilhadoFormatter } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

interface ProfissionaisHistoricoHeaderLabelProps {
  historicoAtendimento: HistoricoAtendimentoModel
  isCuidadoCompartilhadoEvolucao: Boolean
}

export default function ProfissionaisHistoricoHeaderLabel(props: ProfissionaisHistoricoHeaderLabelProps) {
  const { historicoAtendimento, isCuidadoCompartilhadoEvolucao } = props

  if (isCuidadoCompartilhadoEvolucao) {
    const lotacaoExecutante = historicoAtendimento.cuidadoCompartilhadoEvolucao.lotacaoExecutante
    const lotacaoSolicitante = historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante
    return (
      <VFlow vSpacing={0}>
        <HLabel title='Solicitante'>{lotacaoCuidadoCompartilhadoFormatter(lotacaoSolicitante)}</HLabel>
        <HLabel title='Executante'>{lotacaoCuidadoCompartilhadoFormatter(lotacaoExecutante)}</HLabel>
      </VFlow>
    )
  }

  return (
    <Text>
      {`${
        historicoAtendimento.profissional?.nome ?? `CNS: ${historicoAtendimento.cnsProfissional}`
      } - ${historicoAtendimento.cbo.nome.capitalize()} - ${historicoAtendimento.cbo.cbo2002} ${
        historicoAtendimento.equipe
          ? `| Equipe ${historicoAtendimento.equipe?.ine} - ${historicoAtendimento.equipe?.nome}`
          : ''
      }`}
    </Text>
  )
}
