import { HFlow, Icon, SelectMenuItem, Text, VFlow } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { blue } from 'bold-ui/lib/styles/colors'
import { SelectField, selectFieldStatus } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { AntecedenteCiapSelectFieldDocument } from 'graphql/hooks.generated'
import {
  AntecedenteCiapSelectFieldQuery,
  AntecedenteCiapSelectFieldQueryVariables,
  CiapCapituloEnum,
} from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { AntecedentesFamiliaresCiapFormModel } from 'view/atendimentos/detail/soap/antecedentes/model'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type AntecedenteCiapSelectFieldModel = AntecedenteCiapSelectFieldQuery['ciaps']['content'][0]

export interface AntecedenteCiapSelectFieldProps extends AsyncSelectFieldProps<AntecedenteCiapSelectFieldModel> {
  capitulosExcluidos?: CiapCapituloEnum[]
  excluirCIAPsDAB?: boolean
  formValues?: Array<AntecedentesFamiliaresCiapFormModel>
  defaultItens: {
    itens: AntecedenteCiapSelectFieldModel[]
    ids: string[]
  }
}

const idGrupoSugeridos = 'idGrupoSugeridos'
const idGrupoOutrosCiaps = 'idGrupoOutrosCiaps'

const defaultDisabledMenuItemProps = {
  style: {
    cursor: 'not-allowed',
    '&:hover': {
      background: theme.pallete.primary.c100,
    },
  },
}

export function AntecedenteCiapSelectField(props: AntecedenteCiapSelectFieldProps) {
  const { capitulosExcluidos, excluirCIAPsDAB, formValues, defaultItens, itemIsDisabled, ...rest } = props

  const {
    skipping,
    selectProps: { items, ...asyncProps },
  } = useAsyncQuerySelect<
    AntecedenteCiapSelectFieldModel,
    AntecedenteCiapSelectFieldQuery,
    AntecedenteCiapSelectFieldQueryVariables
  >({
    query: AntecedenteCiapSelectFieldDocument,
    extractItems: (data) => data?.ciaps?.content,
    variables: (inputQuery: string): AntecedenteCiapSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        capitulosExcluidos,
        excluirCIAPsDAB,
      },
    }),
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  const selectItems = useMemo<{
    hasCiapsSugeridos: boolean
    hasOutrosCiaps: boolean
    items: AntecedenteCiapSelectFieldModel[]
  }>(() => {
    items?.sort((a, b) => {
      return a.descricao.localeCompare(b.descricao)
    })

    const ciapsSugeridos: AntecedenteCiapSelectFieldModel[] = [
      { id: idGrupoSugeridos, codigo: '', descricao: '' },
    ].concat(defaultItens.itens)

    const outrosCiaps: AntecedenteCiapSelectFieldModel[] = [{ id: idGrupoOutrosCiaps, codigo: '', descricao: '' }]

    // Não duplicar itens na combo com os defaultSugeridos
    items?.forEach((item) => {
      if (!defaultItens.ids.includes(item.id)) outrosCiaps.push(item)
    })

    return {
      hasCiapsSugeridos: ciapsSugeridos.length > 1,
      hasOutrosCiaps: outrosCiaps.length > 1,
      items: outrosCiaps.concat(ciapsSugeridos),
    }
  }, [items, defaultItens.itens, defaultItens.ids])

  const itemToString = (item: AntecedenteCiapSelectFieldModel) => item && `${item.descricao} - ${item.codigo}`

  const renderItem = (item: AntecedenteCiapSelectFieldModel) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{item.descricao}</Text>
        <HLabel title='Código '>{item.codigo}</HLabel>
      </VFlow>
    )
  }

  const Item = (
    itemProps: SelectDownshiftMenuProps<AntecedenteCiapSelectFieldModel> & { item: AntecedenteCiapSelectFieldModel }
  ) => {
    const {
      downshift: { getItemProps },
      item,
      renderItem,
    } = itemProps

    const renderGrupoCiapsSugeridos = itemProps.item?.id === idGrupoSugeridos
    const renderGrupoOutrosCiaps = itemProps.item?.id === idGrupoOutrosCiaps

    if (renderGrupoOutrosCiaps && skipping) return <> </>

    const isDisabled = itemIsDisabled(itemProps.item)

    const menuItemProps = isDisabled ? defaultDisabledMenuItemProps : { style: {} }
    const setOpacity = isDisabled ? { style: { opacity: 0.5 } } : { style: {} }

    return (
      <>
        {renderGrupoCiapsSugeridos || renderGrupoOutrosCiaps ? (
          <RenderGrupoHeader
            renderGrupoOutrosCiaps={renderGrupoOutrosCiaps}
            hasCiapsSugeridos={selectItems.hasCiapsSugeridos}
            hasOutrosCiaps={selectItems.hasOutrosCiaps}
          />
        ) : (
          <SelectMenuItem
            {...menuItemProps}
            {...(isDisabled ? getItemProps({ item, disabled: true }) : getItemProps({ item }))}
          >
            <HFlow alignItems='center' justifyContent='space-between'>
              <div {...setOpacity}>{renderItem(item)}</div>

              {isDisabled && (
                <HFlow alignItems='center' hSpacing={0.2} style={{ color: selectFieldStatus.SUCCESS.color }}>
                  <Icon icon={selectFieldStatus.SUCCESS.icon} size={1} style={{ display: 'block' }} />
                  {'Adicionado'}
                </HFlow>
              )}
            </HFlow>
          </SelectMenuItem>
        )}
      </>
    )
  }

  return (
    <SelectField<AntecedenteCiapSelectFieldModel>
      placeholder='Pesquise ou selecione um CIAP 2'
      itemToString={itemToString}
      items={selectItems.items}
      renderItem={renderItem}
      components={{
        Item,
      }}
      {...asyncProps}
      {...rest}
    />
  )
}

interface GrupoHeaderProps {
  hasCiapsSugeridos: boolean
  hasOutrosCiaps: boolean
  renderGrupoOutrosCiaps: boolean
}

function RenderGrupoHeader(props: GrupoHeaderProps) {
  const { hasCiapsSugeridos, hasOutrosCiaps, renderGrupoOutrosCiaps } = props

  const emptyGroupMessage = renderGrupoOutrosCiaps
    ? !hasOutrosCiaps && 'Nenhuma outra CIAP2.'
    : !hasCiapsSugeridos && 'Nenhuma CID10 relacionada.'

  return (
    <>
      <SelectMenuItem style={{ pointerEvents: 'none', cursor: 'not-allowed', backgroundColor: blue.c90 }}>
        <Text fontWeight='bold' style={{ color: blue.c40 }}>
          {!renderGrupoOutrosCiaps ? 'Opções rápidas para CIAP 2' : 'Outras CIAP 2'}
        </Text>
      </SelectMenuItem>
      {emptyGroupMessage && (
        <SelectMenuItem style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
          <Text>{emptyGroupMessage}</Text>
        </SelectMenuItem>
      )}
    </>
  )
}
