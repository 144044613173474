import { HFlow, Icon, Text } from 'bold-ui'
import theme from 'config/theme'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { ProblemaCondicaoModel } from '../../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { findProblemaComCiapW78Resolvido } from '../../../avaliacao/components/problemas-condicoes/utils/operations'

export function TagGestacaoEncerrada() {
  const {
    input: { value: problemasAvaliados },
  } = useField<ProblemaCondicaoModel[]>(meta.avaliacao.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })
  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(meta.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })

  const {
    prontuario: { preNatalAtivo },
  } = useAtendimentoContext()

  const problemaPreNatalAtivoId = preNatalAtivo?.problema?.id

  const problemaGestacaoEncerradoPelaAvaliacaoId = findProblemaComCiapW78Resolvido(problemasAvaliados)?.problemaId
  const problemaGestacaoEncerradoPelaLPCId = findProblemaComCiapW78Resolvido(problemasCondicoes)?.problemaId

  const isEncerramentoGestacao =
    problemaGestacaoEncerradoPelaAvaliacaoId === problemaPreNatalAtivoId ||
    problemaGestacaoEncerradoPelaLPCId === problemaPreNatalAtivoId

  return (
    <Fragment>
      {isEncerramentoGestacao && (
        <HFlow alignItems='center' hSpacing={0.6}>
          <Icon icon='exclamationTriangleOutline' fill='alert' size={1} />
          <Text style={classes.textAlerta}>Gestação a ser encerrada</Text>
        </HFlow>
      )}
    </Fragment>
  )
}

const classes = {
  textAlerta: {
    color: theme.pallete.status.alert.main,
  },
}
