/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, Theme, useTheme } from 'bold-ui'
import { ChangeEvent, useCallback } from 'react'

import { AgendamentoDia } from '../types/AgendamentoDia'
import { AgendamentosDiaLabel } from './AgendamentosDiaLabel'

export interface AgendamentosDiaCheckFieldProps {
  agendamento: AgendamentoDia
  checked: boolean
  onChange(value: string): void
}

export function AgendamentosDiaCheckField(props: AgendamentosDiaCheckFieldProps) {
  const { agendamento, checked, onChange } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Checkbox
      style={classes.checkbox}
      label={
        <AgendamentosDiaLabel
          nomeProf={agendamento.lotacaoAgendada.profissional.nome}
          horarioInicial={agendamento.horarioInicial}
          cbo={agendamento.lotacaoAgendada.cbo.nome}
          nomeProfParticipante={agendamento.profissionalParticipante?.nome}
          cboProfParticipante={agendamento.profissionalParticipante?.cbo?.nome}
          estabelecimentoProfParticipante={agendamento.profissionalParticipante?.estabelecimento}
        />
      }
      value={agendamento.id}
      onChange={handleChange}
      checked={checked}
    />
  )
}

const createStyles = (theme: Theme) => ({
  checkbox: css`
    width: 100%;
    background: ${theme.pallete.surface.main};
    padding: 1.25rem 1rem 1.25rem 0.75rem;
    border-radius: 2px;
    border: 1px solid ${theme.pallete.gray.c60};
  `,
})
