import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import {
  ClassificacaoPrioridadeCuidadoEnum,
  Estagio,
  Lotacao,
  LotacaoHistoricoCuidadoCompartilhadoHeaderFragment,
  StatusCuidadoCompartilhadoEnum,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import {
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  ResponsavelCuidadoCompartilhado,
} from './model-cuidadoCompartilhado'

type LotacaoFormatterModel = Omit<LotacaoHistoricoCuidadoCompartilhadoHeaderFragment, 'unidadeSaude'> & {
  unidadeSaude: Omit<LotacaoHistoricoCuidadoCompartilhadoHeaderFragment['unidadeSaude'], 'cnes'>
}

export const getResponsabilidadeCuidadoCompartilhado = (
  lotacaoAcessoId: ID,
  lotacaoExecutanteId: ID,
  lotacaoSolicitanteId: ID
): ResponsavelCuidadoCompartilhado => {
  if (lotacaoAcessoId === lotacaoExecutanteId) {
    return 'EXECUTANTE'
  } else if (lotacaoAcessoId === lotacaoSolicitanteId) {
    return 'SOLICITANTE'
  }
  return null
}

export const isResponsavelCuidadoCompartilhado = (
  lotacaoAcessoId: ID,
  lotacaoExecutanteId: ID,
  lotacaoSolicitanteId: ID
) => {
  return getResponsabilidadeCuidadoCompartilhado(lotacaoAcessoId, lotacaoExecutanteId, lotacaoSolicitanteId)!!
}

export const formatCiapCid = (ciap?: CiapSelectFieldModel, cid10?: CidSelectFieldModel) => {
  const separator = ciap ? ' | ' : ''

  const ciapStr = ciap ? `${ciap.descricao.capitalize()} - ${ciap.codigo} (CIAP 2)` : ''
  const cidStr = cid10 ? `${separator + cid10.nome.capitalize()} - ${cid10.codigo} (CID 10)` : ''

  return ciapStr + cidStr
}

export const lotacaoCuidadoCompartilhadoFormatter = (lotacao: LotacaoFormatterModel) => {
  const nome = `${lotacao.profissional.nome} | ${lotacao.cbo.nome.toUpperCase()}`
  const equipe = `${lotacao.equipe ? `| Equipe ${lotacao.equipe.ine} - ${lotacao.equipe.nome}` : ''}`
  const unidadeSaude = `${lotacao.unidadeSaude ? `| ${lotacao.unidadeSaude.nome} ` : ''}`

  return `${nome} ${equipe} ${unidadeSaude}`
}

export const getFieldsByResponsavel = (
  responsavel: ResponsavelCuidadoCompartilhado,
  values: DiscussaoCasoFormModel
): DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel => {
  return responsavel === 'EXECUTANTE' ? values.executanteFields : values.solicitanteFields
}

export const getLotacoesConsultaCompartilhadaCuidadoCompartilhado = (
  lotacaoId: ID,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
) => {
  const { lotacaoExecutanteAtual, lotacaoSolicitante } = cuidadoCompartilhado

  const responsabilidade = getResponsabilidadeCuidadoCompartilhado(
    lotacaoId,
    lotacaoExecutanteAtual.id,
    lotacaoSolicitante.id
  )

  if (responsabilidade === 'EXECUTANTE') {
    return {
      lotacaoResponsavel: lotacaoExecutanteAtual,
      lotacaoConvidada: lotacaoSolicitante,
    }
  }

  if (responsabilidade === 'SOLICITANTE') {
    return {
      lotacaoResponsavel: lotacaoSolicitante,
      lotacaoConvidada: lotacaoExecutanteAtual,
    }
  }

  return { lotacaoResponsavel: null, lotacaoConvidada: null }
}

export const isReclassificandoCuidadoCompartilhado = (
  prioridadeAtual: ClassificacaoPrioridadeCuidadoEnum,
  prioridadeReclassificada: ClassificacaoPrioridadeCuidadoEnum
) => {
  return (
    !!prioridadeReclassificada &&
    !isUndefinedOrNull(prioridadeReclassificada) &&
    prioridadeReclassificada !== prioridadeAtual
  )
}

export const podeAcessarVisualizacaoCuidadoCompartilhado = (
  acesso: Estagio | Lotacao,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
) => {
  return podeAcessarCuidadoCompartilhado(acesso, cuidadoCompartilhado, false)
}

export const podeAcessarDiscussaoCuidadoCompartilhado = (
  acesso: Estagio | Lotacao,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
) => {
  return podeAcessarCuidadoCompartilhado(acesso, cuidadoCompartilhado, true)
}

const podeAcessarCuidadoCompartilhado = (
  acesso: Estagio | Lotacao,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  isDiscussao: boolean
): boolean => {
  const isExecutante = acesso.id === cuidadoCompartilhado.lotacaoExecutanteAtual.id
  const isSolicitante = acesso.id === cuidadoCompartilhado.lotacaoSolicitante.id
  const isEquipeSolicitante = acesso.equipe?.id === cuidadoCompartilhado.lotacaoSolicitante.equipe.id
  const isEquipeExecutante = acesso.equipe?.id === cuidadoCompartilhado.lotacaoExecutanteAtual.equipe.id
  const isStatusValido =
    (isExecutante && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.RESPONDIDO) ||
    (isSolicitante && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.AGUARDANDO)

  return isDiscussao
    ? (isExecutante || isSolicitante) && !isStatusValido
    : isExecutante || isSolicitante || isEquipeSolicitante || isEquipeExecutante
}
