/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Theme, useTheme } from 'bold-ui'
import { blue, green, orange, red, yellow } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { format } from 'date-fns'
import { ClassificacaoRisco, TipoServico } from 'graphql/types.generated'
import { ReactComponent as IconAgendamento } from 'images/atendimento/icon-agendamento.svg'

import { PecTag } from './PecTag'

export interface AtendimentoTagProps {
  horaAgendamento?: Instant
  nomeProfissional?: string
  nomeProfissionalParticipante?: string
  classificacaoRisco?: ClassificacaoRisco
  tipoServico?: TipoServico
  possuiVideochamada?: boolean
}

interface ClassificacaoRiscoType {
  nome: string
  descricao: string
}

function createMap(classes: any) {
  const classificacaoRiscoMap: Record<ClassificacaoRisco, ClassificacaoRiscoType> = {
    [ClassificacaoRisco.PEC_ALTA]: { nome: classes.alta, descricao: 'Risco alto' },
    [ClassificacaoRisco.PEC_INTERMEDIARIA]: { nome: classes.intermediaria, descricao: 'Risco intermediário' },
    [ClassificacaoRisco.PEC_BAIXA]: { nome: classes.baixa, descricao: 'Risco baixo' },
    [ClassificacaoRisco.PEC_NAO_AGUDA]: { nome: classes.nao_aguda, descricao: 'Não aguda' },
    [ClassificacaoRisco.NAO_CLASSIFICADO]: { nome: null, descricao: '' },
  }
  return classificacaoRiscoMap
}

export function AtendimentoTag(props: AtendimentoTagProps) {
  const {
    horaAgendamento,
    nomeProfissional,
    nomeProfissionalParticipante,
    tipoServico,
    classificacaoRisco,
    possuiVideochamada,
  } = props
  const theme = useTheme()
  const classes = createStyles(theme, nomeProfissionalParticipante)
  const classificacaoRiscoMap = createMap(classes)

  if (horaAgendamento) {
    return (
      <PecTag style={[classes.tipo_servico, classes.agendamento]} icon={IconAgendamento}>
        <HFlow style={classes.tagAgendamentoContainer} justifyContent='center' alignItems='center' hSpacing={0}>
          {format(horaAgendamento, 'HH:mm')} |&nbsp;&nbsp;
          <div css={classes.tagAgendamento}>
            <Ellipsis css={classes.nomeProfissional}>{nomeProfissional}</Ellipsis>
            {!!nomeProfissionalParticipante && (
              <Ellipsis
                css={[
                  classes.nomeProfissional,
                  css`
                    font-weight: normal;
                  `,
                ]}
              >
                {`com ${nomeProfissionalParticipante}`}
              </Ellipsis>
            )}
          </div>
        </HFlow>
      </PecTag>
    )
  }

  if (tipoServico) {
    return (
      <PecTag style={classes.tipo_servico}>
        <span css={classes.tagSpan}>{tipoServico.nome}</span>
      </PecTag>
    )
  }

  if (classificacaoRisco && classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO) {
    const risco: ClassificacaoRiscoType = classificacaoRiscoMap[classificacaoRisco]
    return (
      risco && (
        <PecTag style={risco.nome} icon='userFilled'>
          {risco.descricao}
        </PecTag>
      )
    )
  }

  if (possuiVideochamada) {
    return <PecTag style={classes.tipo_servico}>Videochamada</PecTag>
  }
}

const createStyles = (theme: Theme, nomeProfissionalParticipante) => ({
  alta: css`
    background-color: ${red.c90};
    color: ${red.c40};
  `,
  intermediaria: css`
    background-color: ${yellow.c90};
    color: ${orange.c40};
  `,
  baixa: css`
    background-color: ${green.c90};
    color: ${green.c40};
  `,
  nao_aguda: css`
    background-color: ${blue.c90};
    color: ${blue.c40};
  `,
  tipo_servico: css`
    background-color: ${theme.pallete.gray.c90};
    color: ${theme.pallete.gray.c20};
    border-radius: 0.3rem;
  `,
  tagSpan: css`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  agendamento: css`
    max-width: 100%;
    height: ${!!nomeProfissionalParticipante ? '2.25rem' : '1.5rem'};
  `,
  tagAgendamento: css`
    flex: 1;
    display: flex;
    flex-flow: column;
    width: calc(100% - 3rem);
  `,
  tagAgendamentoContainer: css`
    display: flex;
    width: calc(100% - 1.25rem);
  `,
  nomeProfissional: css`
    line-height: 1rem;
  `,
})
