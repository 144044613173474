/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { ErrorField, Form, FormRenderProps, NumberField, SubmitButton, TextAreaField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { GESTAO_GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { FORM_ERROR } from 'final-form'
import { useGarantiaAcessoQuery, useRemoverCidadaoGarantiaAcessoMutation } from 'graphql/hooks.generated'
import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { metaPath } from 'util/metaPath'

import { MotivoSaidaRadioGroupField } from '../components/MotivoSaidaRadioGroupField'
import { GarantiaAcessoRemocaoModel } from '../model-garantiaAcesso'
import { createRemocaoCalculations } from './calculator-garantiaAcesso'
import { convertModelToInput } from './convert-garantiaAcesso'
import { removerCidadaoGarantiaAcessoValidator } from './validator-garantiaAcesso'

const name = metaPath<GarantiaAcessoRemocaoModel>()

interface UrlParams {
  garantiaAcessoId: ID
}

export default function GarantiaAcessoRemoverCidadaoModalForm() {
  const { garantiaAcessoId } = useParams<UrlParams>()
  const { analytics } = useFirebase()
  const history = useHistory()
  const alert = useAlert()
  const [remove] = useRemoverCidadaoGarantiaAcessoMutation()
  const validator = useMemo(removerCidadaoGarantiaAcessoValidator, [])
  const decorator = useMemo(() => createRemocaoCalculations(name), [])

  const {
    data: { garantiaAcessoItemById: garantiaAcesso },
  } = useGarantiaAcessoQuery({
    variables: {
      input: garantiaAcessoId,
    },
  })

  if (!garantiaAcesso?.cidadao) {
    return null
  }

  const goBack = () => history.push(GESTAO_GARANTIA_ACESSO_PATH)

  const handleSubmit = (values: GarantiaAcessoRemocaoModel) => {
    const valuesInput = { ...values, garantiaAcessoId }
    return remove({ variables: { input: { ...convertModelToInput(valuesInput) } } }).then(() => {
      goBack()
      alert('success', 'Registro removido da lista com sucesso.')
      analytics.logEvent('excluir_registro_GGA')
    })
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoRemocaoModel>) => {
    const values = formProps.values
    const cidadaoNaoRespondeu = values.motivoSaida === ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO

    return (
      <Modal onClose={goBack} open size='small'>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Remover registro da lista</Heading>
            <ErrorField name={FORM_ERROR} type='alert' />
            <Grid>
              <Cell size={12}>
                <Heading level={3}>
                  {(garantiaAcesso.cidadao.nomeSocial ?? garantiaAcesso.cidadao.nome).titleCase()}
                </Heading>
                <VFlow vSpacing={0}>
                  <HFlow>
                    <HLabel title='CPF'>
                      <Cpf value={garantiaAcesso.cidadao.cpf} />
                    </HLabel>
                    <HLabel title='CNS'>{garantiaAcesso.cidadao.cns}</HLabel>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell size={12}>
                <MotivoSaidaRadioGroupField name={name.motivoSaida} label='Motivo da remoção' required />
              </Cell>
              <Cell size={7}>
                <NumberField
                  name={name.tentativasContato}
                  label='Número de tentativas de contato'
                  title='Número de tentativas de contato'
                  disabled={!cidadaoNaoRespondeu}
                  required={cidadaoNaoRespondeu}
                  maxLength={3}
                />
              </Cell>
              <Cell size={12}>
                <TextAreaField
                  name={name.observacaoSaida}
                  required={values.motivoSaida === ListaEsperaMotivoSaidaEnum.OUTROS}
                  label='Observações'
                  maxLength={200}
                  style={styles.observacao}
                  title='Observações'
                />
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack} title='Cancelar' size='medium' style={styles.button}>
              Cancelar
            </Button>
            <SubmitButton
              title='Salvar'
              size='medium'
              kind='danger'
              handleSubmit={formProps.handleSubmit}
              style={styles.button}
            >
              Remover
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<GarantiaAcessoRemocaoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      suppressNotificationError
      validate={validator}
      decorators={[decorator]}
    />
  )
}

const styles = {
  observacao: css`
    height: 3.375rem;
    resize: none;
  `,
  button: css`
    height: 3rem;
    width: 9rem;
  `,
}
