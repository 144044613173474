import { Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { css } from 'emotion'
import React from 'react'
import { useRouteMatch } from 'react-router'

import { CuidadoCompartilhadoHeaderTabs } from '../components/CuidadoCompartilhadoHeaderTabs'
import { DiscussaoCasoPageContent } from '../components/DiscussaoCasoPageContent'
import { CuidadoCompartilhadoEvolucoes } from '../cuidado-compartilhado/components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { CuidadoCompartilhadoPanel } from '../cuidado-compartilhado/components/CuidadoCompartilhadoPanel'
import { CheckJustificativaAcessoCuidadoCompartilhado } from '../cuidado-compartilhado/justificativa/CheckJustificativaCuidadoCompartilhado'
import { CidadaoCuidadoCompartilhado, CuidadoCompartilhadoModel } from '../model-cuidadoCompartilhado'

interface VisualizarCuidadoCompartilhadoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const VisualizarCuidadoCompartilhadoView = (props: VisualizarCuidadoCompartilhadoViewProps) => {
  const { cuidadoCompartilhado } = props
  const match = useRouteMatch()

  const renderDiscussaoCaso = (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => (
    <CheckJustificativaAcessoCuidadoCompartilhado
      cidadaoId={cidadao.id}
      prontuarioId={cuidadoCompartilhado.cidadao.prontuario.id}
      cuidadoCompartilhadoId={cuidadoCompartilhado.id}
      isDiscutirCaso={false}
      basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
    >
      <DiscussaoCasoPageContent
        cuidadoCompartilhado={cuidadoCompartilhado}
        cidadao={cidadao}
        headerHeight={headerHeight}
      >
        <VFlow>
          <CuidadoCompartilhadoPanel cuidadoCompartilhado={cuidadoCompartilhado} />

          <VFlow vSpacing={0.5}>
            <Heading level={3}>Discussão</Heading>

            <Box
              style={css`
                padding: 1rem 0;
              `}
            >
              <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhado.id} />
            </Box>
          </VFlow>
        </VFlow>
      </DiscussaoCasoPageContent>
    </CheckJustificativaAcessoCuidadoCompartilhado>
  )

  return (
    <>
      <Breadcrumb title='Visualizar discussão' />
      <CuidadoCompartilhadoHeaderTabs
        cuidadoCompartilhado={cuidadoCompartilhado}
        renderDiscussaoCaso={renderDiscussaoCaso}
        isVisualizacao={true}
      />
    </>
  )
}
