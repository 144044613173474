/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Cell, Grid, Text, VFlow } from 'bold-ui'
import { Form, TextField } from 'components/form'
import { FormApi } from 'final-form'
import { useCompartilharVideochamadaMutation } from 'graphql/hooks.generated'
import { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, email, required } from 'util/validation'

interface EnviarEmailVideochamadaProps {
  videochamadaUuid: string
  onSuccess(): void
  onError(): void
}

interface EnviarEmailVideochamadaModel {
  email: string
}

const validator = createValidator<EnviarEmailVideochamadaModel>({
  email: [email, required],
})

const meta = metaPath<EnviarEmailVideochamadaModel>()

export function EnviarEmailVideochamadaForm({ videochamadaUuid, onSuccess, onError }: EnviarEmailVideochamadaProps) {
  const [loading, setLoading] = useState(false)

  const [compartilharVideochamada] = useCompartilharVideochamadaMutation()

  const handleEnviarEmail = (values: EnviarEmailVideochamadaModel, form: FormApi) => {
    setLoading(true)
    compartilharVideochamada({
      variables: {
        input: {
          videochamadaUuid,
          emailDestinatario: values.email,
        },
      },
    })
      .then(() => {
        setTimeout(() => form.getRegisteredFields().forEach((field) => form.resetFieldState(field)))
        setTimeout(form.reset)
        onSuccess()
      })
      .finally(() => setLoading(false))
      .catch(onError)
  }

  const render = ({ handleSubmit }: FormRenderProps<EnviarEmailVideochamadaModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      <VFlow vSpacing={0.5}>
        <Text fontWeight='bold'>Enviar por e-mail:</Text>
        <Grid alignItems='center' gap={0.5}>
          <Cell flexGrow={1}>
            <TextField
              name={meta.email}
              placeholder='Adicione o e-mail do convidado'
              disabled={loading}
              aria-label='Input de texto de email'
            />
          </Cell>
          <Cell alignSelf='flex-start'>
            <Button type='submit' loading={loading} kind='primary' size='small' aria-label='Enviar e-mail'>
              Enviar
            </Button>
          </Cell>
        </Grid>
      </VFlow>
    </form>
  )

  return (
    <Form<EnviarEmailVideochamadaModel>
      render={render}
      onSubmit={handleEnviarEmail}
      validate={validator}
      suppressNotificationError
    />
  )
}
