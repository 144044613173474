import { GarantiaAcessoAgendarConsultaInput } from 'graphql/types.generated'
import { convertAgendarConsulta } from 'view/atendimentos/detail/soap/finalizacao'

import { GarantiaAcessoAgendaModel } from './GarantiaAcessoAgendarConsultaModalForm'

export const convertModelToInput = (
  values: GarantiaAcessoAgendaModel,
  garantiaAcessoId: ID
): GarantiaAcessoAgendarConsultaInput => ({
  garantiaAcessoId: garantiaAcessoId,
  agendamentoConsultaInput: convertAgendarConsulta(values.agendamentoConsultaInput),
})
