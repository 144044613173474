import api from 'api'
import { AxiosPromise, AxiosResponse } from 'axios'
import { AtestadoRecenteImpressaoInput } from 'view/atendimentos/detail/soap/plano/atestados/model-atestado'
import { ImpressaoMedicamentosInput } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/modal/impressao/ImpressaoMedicamentosInput'
import { PrescricaoDigitalErrorCodeEnum } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/modal/prescricao-digital/PrescricaoDigitalIntegracaoMessage'

export enum TipoDocumentoAssinaturaDigitalPopup {
  PRESCRICAO,
  ATESTADO,
}

type StatusAssinaturaDigitalPopup = 'success' | 'failure' | 'canceled'

export const INTERVAL_DELAY_ASSINATURA_DIGITAL_POPUP = 500 // ms

export type GerarAssinaturaDigitalInputType = ImpressaoMedicamentosInput | AtestadoRecenteImpressaoInput

export interface AssinaturaDigitalPopupResult<T> {
  data: AssinaturaDigitalPopupResponse
  openPopup: (input: T) => Promise<void>
  closePopup: () => void
}

export interface AssinaturaDigitalPopupResponse {
  status?: StatusAssinaturaDigitalPopup
  response?: AxiosResponse
  codigoDocumento?: string
  error?: PrescricaoDigitalErrorCodeEnum
  identifier: string
}

export interface EmitirAssinaturaDigitalInput extends EmitirAssinaturaDigitalPopupInput {
  codigoDocumento: string
}

export interface EmitirAssinaturaDigitalPopupInput {
  atendimentoId: ID
  cidadaoId: ID
  emailCidadao: string
}

interface AssinaturaDigitalPopupConfig {
  target: string
  gerar: (input: GerarAssinaturaDigitalInputType) => AxiosPromise
  emitir: (input: EmitirAssinaturaDigitalInput) => AxiosPromise
}

export const configAssinaturaDigitalByTipoDocumento: Record<
  TipoDocumentoAssinaturaDigitalPopup,
  AssinaturaDigitalPopupConfig
> = {
  [TipoDocumentoAssinaturaDigitalPopup.PRESCRICAO]: {
    target: 'prescricao-digital',
    gerar: api.prescricaoDigital.gerarPrescricaoDigital,
    emitir: api.prescricaoDigital.emitirPrescricaoDigital,
  },
  [TipoDocumentoAssinaturaDigitalPopup.ATESTADO]: {
    target: 'atestado-digital',
    gerar: api.atestado.gerarAtestadoDigital,
    emitir: api.atestado.emitirAtestadoDigital,
  },
}
