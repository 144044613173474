import { SessionProviderEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useSessionStorage } from 'hooks/useStorage'
import { useCallback } from 'react'

const LOCAL_STORAGE_LOGIN_ANALYTICS_KEY = 'LOGGED_SESSION_PROVIDER_INTO_ANALYTICS'

export default () => {
  const { analytics } = useFirebase()
  const [loggedIntoAnalytics, setLoggedIntoAnalytics, remove] = useSessionStorage(
    LOCAL_STORAGE_LOGIN_ANALYTICS_KEY,
    false
  )

  const log = useCallback(
    (sessionProvider: SessionProviderEnum) => {
      if (!loggedIntoAnalytics && sessionProvider) {
        analytics.logEvent(`logar_via_${sessionProvider}`)
        setLoggedIntoAnalytics(true)
      }
    },
    [analytics, loggedIntoAnalytics, setLoggedIntoAnalytics]
  )

  return {
    log,
    remove,
  }
}
