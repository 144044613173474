/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HeadingSection, Icon, isEmpty, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { PrivateRoute } from 'components/route'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useCallback, useMemo } from 'react'
import { useField } from 'react-final-form'
import { Switch, useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { grupoCboPrescricao } from '../acessos'
import AccordionPrescricao from './components/AccordionPrescricao'
import PrescricaoDigitalTable from './components/PrescricaoDigitalTable'
import HistoricoPrescricaoTable from './historico-prescricao/HistoricoPrescricaoTable'
import AdicionarPrescricaoModal from './modal/AdicionarPrescricaoModal'
import { PrescricaoDigitalFormModel } from './modal/prescricao-digital/model-prescricaoDigital'
import { MedicamentoFormModel } from './model'
import { hasPrescricaoDigitalAtiva } from './utils/prescricaoUtils'

interface PrescricaoMedicamentoViewProps {
  name: MetaPath<PrescricaoMedicamentoPlanoModel>
  prontuarioId: ID
  atendimentoId: ID
  medicamentos: MedicamentoFormModel[]
  isAtendimentoProcedimentos: boolean
  dataReferencia: Instant
  nomeCidadao: string
}

export interface PrescricaoMedicamentoPlanoModel {
  medicamentos: MedicamentoFormModel[]
  replicateStorage: MedicamentoFormModel[]
  alreadyPrinted: ID[]
  prescricaoDigital: PrescricaoDigitalFormModel
}

export default function PrescricaoMedicamentoView(props: PrescricaoMedicamentoViewProps) {
  const {
    name,
    prontuarioId,
    atendimentoId,
    medicamentos,
    isAtendimentoProcedimentos,
    dataReferencia,
    nomeCidadao,
  } = props
  const history = useHistory()
  const match = useRouteMatch()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const {
    observacao: { isAtendimentoObservacao },
    atendimentoProfissional: { id: atendimentoProfissionalId },
  } = useAtendimentoContext()

  const {
    input: { value: prescricaoDigitalField, onChange: updatePrescricaoDigitalField },
  } = useField<PrescricaoDigitalFormModel>(meta.plano.prescricaoMedicamento.prescricaoDigital.absolutePath(), {
    subscription: { value: true },
  })

  const {
    input: { onChange: onChangeReplicate, value },
  } = usePecField({ name: name.replicateStorage })

  const hasPrescricao = medicamentos && !isEmpty(medicamentos)
  const hasPrescricaoDigital = !!prescricaoDigitalField && Object.keys(prescricaoDigitalField).length !== 0
  const podeAdicionarPrescricao = !isAtendimentoProcedimentos && hasCboAuth(grupoCboPrescricao.adicionar)
  const handleOnClearReplicate = useCallback(() => onChangeReplicate(null), [onChangeReplicate])

  const handleClick = useCallback(async () => {
    if (hasPrescricaoDigital && hasPrescricaoDigitalAtiva(prescricaoDigitalField)) {
      const confirmed = await confirm({
        title: 'Deseja editar a prescrição já enviada para o cidadão?',
        body:
          'Uma prescrição digital já foi assinada e enviada para o cidadão. Caso deseje editar a prescrição, verifique se não é necessário invalidar a prescrição digital já enviada e gerar uma nova.',
        confirmLabel: 'Editar',
        depthLevel: 2,
        manageOverflow: false,
      })()

      if (!confirmed) {
        return
      }
    }
    history.push(`${match.url}/prescricao-medicamentos/adicionar`)
  }, [hasPrescricaoDigital, history, match.url, prescricaoDigitalField])

  return useMemo(
    () => (
      <React.Fragment>
        <HeadingSection level={4} title='Prescrição de medicamentos' vSpace={8} />
        {podeAdicionarPrescricao && (
          <VFlow>
            <Button
              kind='primary'
              size='small'
              data-cy='PrescricaoMedicamentos.AdicionarPrescricao'
              onClick={handleClick}
            >
              <Icon
                icon={hasPrescricao ? 'penOutline' : 'plus'}
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              {hasPrescricao ? `Editar prescrição` : `Adicionar prescrição`}
            </Button>
          </VFlow>
        )}
        <Switch>
          <PrivateRoute
            path={`${match.url}/prescricao-medicamentos/adicionar`}
            permission={grupoCboPrescricao.adicionar}
          >
            <AdicionarPrescricaoModal
              name={name}
              atendimentoId={atendimentoId}
              medicamentosToReplicate={value}
              onChangeMedicamentosToReplicate={onChangeReplicate}
              onClearMedicamentosToReplicate={handleOnClearReplicate}
            />
          </PrivateRoute>
        </Switch>
        {hasPrescricao && podeAdicionarPrescricao && (
          <VFlow
            style={css`
              margin-top: 1rem;
            `}
          >
            <AccordionPrescricao
              medicamentos={medicamentos}
              prescricaoDigital={prescricaoDigitalField}
              updatePrescricaoDigital={updatePrescricaoDigitalField}
              atendimentoId={atendimentoId}
            />
          </VFlow>
        )}
        {hasPrescricaoDigital && (
          <div
            css={css`
              margin-top: 1rem;
            `}
          >
            <PrescricaoDigitalTable
              atendimentoId={atendimentoId}
              prescricaoDigital={prescricaoDigitalField}
              updatePrescricaoDigital={updatePrescricaoDigitalField}
              nomeCidadao={nomeCidadao}
            />
          </div>
        )}
        <VFlow
          style={css`
            margin-top: 1rem;
          `}
        >
          <HistoricoPrescricaoTable
            prontuarioId={prontuarioId}
            isAtendimentoProcedimento={isAtendimentoProcedimentos}
            isAtendimentoObservacao={isAtendimentoObservacao}
            atendimentoProfissionalId={atendimentoProfissionalId}
            setMedicamentosToReplicate={onChangeReplicate}
            dataReferencia={dataReferencia}
          />
        </VFlow>
      </React.Fragment>
    ),
    [
      podeAdicionarPrescricao,
      handleClick,
      hasPrescricao,
      match.url,
      name,
      atendimentoId,
      value,
      onChangeReplicate,
      handleOnClearReplicate,
      medicamentos,
      prescricaoDigitalField,
      updatePrescricaoDigitalField,
      hasPrescricaoDigital,
      nomeCidadao,
      prontuarioId,
      isAtendimentoProcedimentos,
      isAtendimentoObservacao,
      atendimentoProfissionalId,
      dataReferencia,
    ]
  )
}
