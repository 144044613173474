import { Cell, Grid, TabItem, Tabs } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { useFlags } from 'config/useFlagsContext'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'

import {
  grupoCboAtestado,
  grupoCboCuidadoCompartilhado,
  grupoCboEncaminhamentoExterno,
  grupoCboOrientacoes,
  grupoCboPrescricao,
  grupoCboSolicitacaoExame,
} from '../acessos'

export enum PlanoTabsEnum {
  PRESCRICAO_MEDICAMENTOS,
  ATESTADO,
  EXAMES,
  ENCAMINHAMENTO_EXTERNO,
  DECLARACAO_COMPARECIMENTO,
  ORIENTACOES,
  CUIDADO_COMPARTILHADO,
}

interface PlanoTabsProps {
  isAtendimentoProcedimentos: boolean
  isFolhaRosto?: boolean
  isObservacaoAndAuxiliar?: boolean
  children: (currentTab: PlanoTabsEnum) => React.ReactNode
}

export const PlanoTabs = (props: PlanoTabsProps) => {
  const { isAtendimentoProcedimentos, isFolhaRosto, isObservacaoAndAuxiliar, children } = props

  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED } = useFlags()
  const { hasAuthorization, hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const [currentPlanoTab, setCurrentPlanoTab] = useState(PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS)

  const showAtestado = !isAtendimentoProcedimentos && hasCboAuth(grupoCboAtestado.visualizar)
  const showDeclaracao =
    !showAtestado && hasAuthorization(Permissions.visualizarListaDeAtendimento.gerarDeclaracaoDeComparecimento)
  const showPrescricao = hasCboAuth(grupoCboPrescricao.visualizar)
  const showExames = hasCboAuth(grupoCboSolicitacaoExame.visualizar)
  const showEncaminhamento = !isAtendimentoProcedimentos
  const showCuidadoCompartilhado =
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED &&
    !isObservacaoAndAuxiliar &&
    hasCboAuth(grupoCboCuidadoCompartilhado) &&
    !isAtendimentoProcedimentos

  return (
    <Grid>
      <Cell size={12}>
        <Tabs>
          {showPrescricao && (
            <TabItem
              defaultChecked
              active={currentPlanoTab === PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS}
              onClick={() => {
                setCurrentPlanoTab(PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS)
              }}
            >
              Prescrição de medicamentos
            </TabItem>
          )}
          {showExames && (
            <TabItem
              active={currentPlanoTab === PlanoTabsEnum.EXAMES}
              onClick={() => setCurrentPlanoTab(PlanoTabsEnum.EXAMES)}
            >
              Solicitação de exames
            </TabItem>
          )}
          {showAtestado && (
            <TabItem
              active={currentPlanoTab === PlanoTabsEnum.ATESTADO}
              onClick={() => setCurrentPlanoTab(PlanoTabsEnum.ATESTADO)}
            >
              Atestados
            </TabItem>
          )}
          <CheckPermission permission={grupoCboOrientacoes.visualizar}>
            <TabItem
              active={currentPlanoTab === PlanoTabsEnum.ORIENTACOES}
              onClick={() => setCurrentPlanoTab(PlanoTabsEnum.ORIENTACOES)}
            >
              Orientações
            </TabItem>
          </CheckPermission>
          {showEncaminhamento && (
            <CheckPermission permission={grupoCboEncaminhamentoExterno}>
              <TabItem
                active={currentPlanoTab === PlanoTabsEnum.ENCAMINHAMENTO_EXTERNO}
                onClick={() => setCurrentPlanoTab(PlanoTabsEnum.ENCAMINHAMENTO_EXTERNO)}
              >
                Encaminhamentos
              </TabItem>
            </CheckPermission>
          )}
          {showDeclaracao && !isFolhaRosto && (
            <TabItem
              active={currentPlanoTab === PlanoTabsEnum.DECLARACAO_COMPARECIMENTO}
              onClick={() => setCurrentPlanoTab(PlanoTabsEnum.DECLARACAO_COMPARECIMENTO)}
            >
              Declaração de comparecimento
            </TabItem>
          )}

          {showCuidadoCompartilhado && (
            <CheckPermission
              permission={grupoCboCuidadoCompartilhado}
              tiposEstabelecimento={[TipoEstabelecimentoEnum.UBS]}
              acceptEstagio={false}
            >
              <TabItem
                active={currentPlanoTab === PlanoTabsEnum.CUIDADO_COMPARTILHADO}
                onClick={() => setCurrentPlanoTab(PlanoTabsEnum.CUIDADO_COMPARTILHADO)}
              >
                Compartilhamento de cuidado
              </TabItem>
            </CheckPermission>
          )}
        </Tabs>
      </Cell>
      <Cell size={12}>{children(currentPlanoTab)}</Cell>
    </Grid>
  )
}
