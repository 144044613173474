import { addMonths, isAfter, isBefore, parseISO, startOfDay } from 'date-fns'
import { createValidator, maxLength, required } from 'util/validation'

import { AgendarConsultaSectionModel } from '../components/AgendarConsultaSection'

export const agendarConsultasValidator = (getServerTimeNow: () => Date, isProximaConsulta?: boolean) =>
  createValidator<AgendarConsultaSectionModel>(
    {
      observacoes: [maxLength(200)],
      ...(isProximaConsulta && {
        lotacao: [required],
      }),
    },
    (values, error) => {
      if (values?.lotacao) {
        error.data = required(values.data)
        const serverTime = getServerTimeNow()
        const dataParsed = parseISO(values?.data)

        if (isAfter(dataParsed, addMonths(serverTime, 6))) {
          error.data = 'Não é permitido agendar para datas superiores a 6 meses'
        } else if (isBefore(dataParsed, startOfDay(serverTime))) {
          error.data = 'Não é permitido agendar para datas anteriores à data atual'
        }

        if (!error.data) {
          error.horario = { inicial: required(values.horario?.inicial) }
          if (!error.horario.inicial && isBefore(values.horario?.inicial, serverTime))
            error.horario = { inicial: 'Horário não pode ser anterior ao horário atual' }
        }
      }

      if (values?.isForaUbs) {
        error.localAtendimento = required(values.localAtendimento)
      }

      return error
    }
  )
