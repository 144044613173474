import { Calculation } from 'final-form-calculate'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { createMedicoesCalculations } from 'view/atendimentos/components/MedicoesPanel/calculator'

import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { createPreNatalObjetivoCalculations } from '../pre-natal/calculator-preNatal'
import { FormAtivoObjetivoEnum, SwitchButtonObjetivoFormModel } from './components/SwitchButtonObjetivoForm'
import { createMarcadoresConsumoAlimentarCalculations } from './marcadores-consumo-alimentar/calculator'
import { ObjetivoFormModel } from './ObjetivoForm'

export const createObjetivoCalculations = (
  meta: MetaPath<ObjetivoFormModel>,
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isGestante: boolean,
  hasPermissionPreNatal: boolean
): Calculation[] => [
  ...createMedicoesCalculations(meta.medicoes),
  ...createPreNatalObjetivoCalculations(meta, avaliacao, isGestante, hasPermissionPreNatal),
  ...createAtendimentosEspecificosObjetivoCalculations(meta.atendimentosEspecificos),
  ...createMarcadoresConsumoAlimentarCalculations(meta.marcadoresConsumoAlimentar),
]

const createAtendimentosEspecificosObjetivoCalculations = (
  atendimentosEspecificos: MetaPath<SwitchButtonObjetivoFormModel>
): Calculation[] => [
  {
    field: [atendimentosEspecificos.formAtivo.absolutePath()],
    updates: {
      [atendimentosEspecificos.puericultura.absolutePath()]: (value: FormAtivoObjetivoEnum, allValues: SoapState) =>
        value !== FormAtivoObjetivoEnum.PUERICULTURA ? null : allValues.objetivo.atendimentosEspecificos.puericultura,
    },
  },
]
