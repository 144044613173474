import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { ReactComponent as Agenda } from 'images/side-menu/agenda.svg'
import { ReactComponent as Cidadao } from 'images/side-menu/cidadao.svg'
import { ReactComponent as Vacinacao } from 'images/side-menu/lista-de-atendimento.svg'
import React from 'react'

import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelV4_50() {
  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Módulos com novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Agenda
          </Text>
          <Text fontSize={0.875} component='li'>
            Agendamentos do cidadão
          </Text>
          <Text fontSize={0.875} component='li'>
            Atendimento de vacinação
          </Text>
        </VFlow>
      </HFlow>
      <NewsHeadingSection
        title='Agenda'
        icon={<Agenda style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='agenda-4-5'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='p'>
            O módulo de Agenda está de cara nova. A partir desta versão:
          </Text>
          <Text fontSize={0.875} component='li'>
            Agendamentos de reservas são apresentados como um único evento.
          </Text>
          <Text fontSize={0.875} component='li'>
            Mais agendamentos são apresentado ao mesmo tempo na tela.
          </Text>
          <Text fontSize={0.875} component='li'>
            Remoção dos períodos: agora todos os horários do dia são apresentados na mesma tela.
          </Text>
          <NewsGif
            src='/static/novidades/4-5_gif_agenda.gif'
            pausedSrc='/static/novidades/4-5_gif_agenda_paused.png'
            alt='Gif Novidades Agenda'
          />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Agendamentos do cidadão'
        icon={<Cidadao style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='agendamentos-cidacao-4-5'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='p'>
            A listagem de agendamentos do cidadão pode ser visualizada em uma nova aba na tela de Visualização de
            Cadastro de Cidadão.
          </Text>
          <Text fontSize={0.875} component='li'>
            A partir desta versão são apresentados todos os agendamentos realizados na mesma instalação, incluindo os
            agendamentos da Atenção Domiciliar.
          </Text>
          <NewsImg src='/static/novidades/4-5_agendamento_cidadao.png' alt='Imagem com novidades do módulo Agenda' />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Atendimento de vacinação'
        icon={<Vacinacao style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='atendimento-vacinacao-4-5'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='p'>
            O atendimento de vacinação teve seu visual redesenhando, deixando mais moderno e fácil de usar. Além disso,
            as seguintes alterações foram realizadas:
          </Text>
          <Text fontSize={0.875} component='li'>
            Agora é possível visualizar, através dos cards das doses dos imunobiológicos, quantas vezes esta dose foi
            aplicada no(a) cidadã(o).
          </Text>
          <Text fontSize={0.875} component='li'>
            As legendas de status das doses foram atualizadas para adequar à realidade do profissional no dia a dia.
          </Text>
          <Text fontSize={0.875} component='li'>
            Ainda, visando melhorar a organização das funcionalidades, o Registro Anterior, Aprazamento e Aplicação
            podem ser acessadas por botões exclusivos, que redirecionarão o usuário para a funcionalidade acessada.
          </Text>
          <NewsGif
            src='/static/novidades/4-5_gif_atendimento_vacinacao.gif'
            pausedSrc='/static/novidades/4-5_gif_atendimento_vacinacao_paused.png'
            alt='Gif Novidades Atendimento de Vacinação'
          />
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
