/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading/LoadingIndicator'
import { useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery } from 'graphql/hooks.generated'
import {
  HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
  HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment,
  HistoricoCuidadoCompartilhadoHeaderFragment,
  LotacaoHistoricoCuidadoCompartilhadoHeaderFragment,
} from 'graphql/types.generated'
import {
  IdadeOrigemAtendimento,
  InformacoesAdministrativas,
} from 'view/atendimentos/detail/historico/model/model-historico'
import { lotacaoToInformacoesAdministrativasProfissional } from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { ClassificacaoPrioridadeCuidadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import StatusSquare from '../../../StatusSquare'
import CiapCidLabel from '../../header/components/CiapCidLabel'
import HistoricoGapPanel from '../HistoricoGapPanel'
import { HistoricoCuidadoCompartilhadoConduta } from './HistoricoCuidadoCompartilhadoConduta'

interface HistoricoCuidadoCompartilhadoPanelProps {
  cuidadoCompartilhadoHeader: HistoricoCuidadoCompartilhadoHeaderFragment
  cuidadoCompartilhadoEvolucaoHeader: HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

export type CuidadoCompartilhadoEvolucaoDetalhes = HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery['cuidadoCompartilhadoEvolucao']

export const HistoricoCuidadoCompartilhadoPanel = (props: HistoricoCuidadoCompartilhadoPanelProps) => {
  const { cuidadoCompartilhadoHeader, cuidadoCompartilhadoEvolucaoHeader, idadeOrigemAtendimento } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const {
    data: { cuidadoCompartilhadoEvolucao: evolucaoDetalhes },
    loading,
  } = useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery({
    variables: {
      id: cuidadoCompartilhadoEvolucaoHeader.id,
    },
  })

  if (loading) return <LoadingIndicator />
  if (!evolucaoDetalhes || !cuidadoCompartilhadoHeader || !cuidadoCompartilhadoEvolucaoHeader) return null

  const informacoesAdministrativas = getInformacoesAdministrativas(
    evolucaoDetalhes,
    cuidadoCompartilhadoHeader.lotacaoSolicitante,
    cuidadoCompartilhadoEvolucaoHeader.lotacaoExecutante
  )

  const classificacaoPrioridade =
    evolucaoDetalhes.reclassificacaoPrioridade &&
    ClassificacaoPrioridadeCuidadoRecord[evolucaoDetalhes.reclassificacaoPrioridade]

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={informacoesAdministrativas}
      dataInicioCompartilhamento={evolucaoDetalhes.cuidadoCompartilhado.iniciadoEm}
    >
      <VFlow vSpacing={0.5}>
        <VFlow vSpacing={0}>
          <Heading level={5}>Problemas e condições</Heading>
          <CiapCidLabel ciap={cuidadoCompartilhadoHeader.ciap} cid10={cuidadoCompartilhadoHeader.cid10} isCapitalized />
        </VFlow>
        <VFlow vSpacing={0}>
          <Heading level={5}>Discussão de caso clínico</Heading>
          <Text
            dangerouslySetInnerHTML={{ __html: evolucaoDetalhes.cuidadoCompartilhado.discussao }}
            style={styles.richText}
          />
        </VFlow>
      </VFlow>

      <VFlow vSpacing={0.5}>
        <Heading level={4}>Conduta</Heading>

        {evolucaoDetalhes.reclassificacaoPrioridade && (
          <HFlow hSpacing={0.5}>
            <Heading level={5}>Reclassificação de prioridade para</Heading>
            <StatusSquare color={classificacaoPrioridade.cor} description={classificacaoPrioridade.descricao} />
          </HFlow>
        )}

        <VFlow vSpacing={0}>
          <HistoricoCuidadoCompartilhadoConduta cuidadoCompartilhadoEvolucao={evolucaoDetalhes} />
        </VFlow>
      </VFlow>
    </HistoricoGapPanel>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    padding: 1em;
    border: 1px solid ${theme.pallete.divider};
    margin-bottom: 0px;
  `,
  richText: css`
    color: ${theme.pallete.gray.c20};
    word-break: break-all;

    & p:first-child {
      display: inline;
    }
  `,
})

const getInformacoesAdministrativas = (
  detalhes: CuidadoCompartilhadoEvolucaoDetalhes,
  lotacaoSolicitante: LotacaoHistoricoCuidadoCompartilhadoHeaderFragment,
  lotacaoExecutante: LotacaoHistoricoCuidadoCompartilhadoHeaderFragment
): InformacoesAdministrativas => {
  const atendimento = detalhes.cuidadoCompartilhado.atendimentoProfissional.atendimento
  const principal = detalhes.lotacao
  const compartilhado = principal.id === lotacaoSolicitante.id ? lotacaoExecutante : lotacaoSolicitante

  return {
    localAtendimento: atendimento?.localAtendimento?.localAtendimentoExibicao,
    stCuidadoCompartilhado: true,
    profissionalPrincipal: lotacaoToInformacoesAdministrativasProfissional(principal),
    profissionalCompartilhado: lotacaoToInformacoesAdministrativasProfissional(compartilhado),
  }
}
