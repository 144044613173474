import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { differenceInCalendarDays } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { useLocalStorage } from 'hooks/useStorage'
import { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS } from '../model'

/*
  Atualiza se já marcou o alert como visto há mais de 7 dias
  baseado no lastDailyUpdate
 */

export function useListaAtendimentosNaoFinalizadosAlert(lastDailyUpdate: Instant, isRegistroTardio: boolean) {
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()

  const localStorageKey = isRegistroTardio ? 'registro-tardio' : 'lista-atendimento'

  const [deveMostrarAlertNovamente, setDeveMostrarAlertNovamente] = useState(false)
  const [dataQueViuAlert, setDataQueViuAlert] = useLocalStorage<Instant>(
    `${acesso.id}/${localStorageKey}-already-seen-alert`
  )

  const setAlertComoVisto = () => {
    setDataQueViuAlert(getServerTimeNow().getTime())
  }

  useEffect(() => {
    setDeveMostrarAlertNovamente(
      !isUndefinedOrNull(dataQueViuAlert) &&
        differenceInCalendarDays(lastDailyUpdate, dataQueViuAlert) > LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS
    )
  }, [dataQueViuAlert, lastDailyUpdate])

  return {
    marcouAlertComoVisto: !isUndefinedOrNull(dataQueViuAlert),
    deveMostrarAlertNovamente,
    setAlertComoVisto,
  }
}
