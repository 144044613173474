import { DateRange } from 'bold-ui'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'
import { ListaAtendimentoFilterPopperFormModel } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'

import { STATUS_REGISTRO_FINALIZADO } from '../model'
import { ListaRegistroTardioFilterPopperModel } from './ListaRegistroTardioFilterPopperForm'

const meta = metaPath<ListaRegistroTardioFilterPopperModel>()

export const createRegistroTardioFilterPopperCalculator = (defaultPeriodo: DateRange) => {
  return createDecorator({
    field: meta.isSomenteNaoFinalizados.absolutePath(),
    updates: {
      [meta.statusAtendimento.absolutePath()]: (value: boolean, allValues: ListaRegistroTardioFilterPopperModel) => {
        return value && allValues.statusAtendimento?.length > 0
          ? allValues.statusAtendimento.filter((item) => !STATUS_REGISTRO_FINALIZADO.includes(item))
          : allValues.statusAtendimento
      },
      [meta.periodo.absolutePath()]: (
        value: boolean,
        allValues: ListaAtendimentoFilterPopperFormModel,
        prevValues: ListaAtendimentoFilterPopperFormModel
      ) => {
        return value
          ? { startDate: undefined, endDate: undefined }
          : prevValues.isSomenteNaoFinalizados
          ? defaultPeriodo
          : allValues.periodo
      },
    },
  })
}
