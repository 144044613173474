/** @jsx jsx */
import { css } from '@emotion/core'
import { blue, gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { format } from 'date-fns'
import {
  EscutaInicialDetailQuery,
  HistoricoAtendimentoDomiciliarAdQuery,
  HistoricoAtendimentoOdontoPecQuery,
  HistoricoAvaliacaoElegibilidadeAdQuery,
  HistoricoConsultaPecQuery,
  HistoricoEscutaInicialQuery,
  HistoricoPreNatalPecQuery,
  HistoricoPuericulturaPecQuery,
  HistoricoPuerperioPecQuery,
  HistoricoVacinacaoPecQuery,
  MarcadorConsumoAlimentar,
  OrigemAtendimento,
  PerguntasQuestionarioEnum,
  TipoAtendimentoProfissional,
  TipoRegistroHistoricoClinico,
} from 'graphql/types.generated'
import { turnoRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { EvolucaoProblema } from '../../soap/aside/types/ProblemaModel'
import { OpcoesMarcadoresConsumoAlimentarRecord } from '../../soap/objetivo/marcadores-consumo-alimentar/model'
import {
  HistoricoAtendimentoModel,
  InformacoesAdministrativas,
  InformacoesAdministrativasProfissional,
  MEDICOES_ATENDIMENTO_PEC,
  MEDICOES_PRE_NATAL_PEC,
} from '../model/model-historico'

export const isHistoricoPrintEnable = (historico: HistoricoAtendimentoModel) => {
  const { hasObservacao, origemAtendimento, isAtendObsFinalizado } = historico

  const isFicha = isFichaAtendimento(origemAtendimento)

  if (hasObservacao && (!isAtendObsFinalizado || isFicha)) return false

  const isAtendimentoADAndNotElegibilidade =
    origemAtendimento === OrigemAtendimento.AD &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE

  return (
    (origemAtendimento === OrigemAtendimento.PEC || isAtendimentoADAndNotElegibilidade) &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.ESCUTA_INICIAL &&
    historico.tipoApresentacao !== TipoRegistroHistoricoClinico.ESCUTA_INICIAL &&
    !historico.isCancelado
  )
}

export const hasSomeSubmodulesPlano = (form: HistoricoAtendimentoModel) =>
  form.hasAlergia ||
  form.hasPrescricaoMedicamento ||
  form.hasOrientacao ||
  form.hasAtestado ||
  form.hasEncaminhamento ||
  form.hasEncaminhamentoEspecializado ||
  form.hasSolicitacaoExame ||
  form.hasResultadoExame ||
  form.hasProcedimentoClinico ||
  form.hasMarcadorConsumoAlimentar ||
  form.hasCuidadoCompartilhado

/**
 * Check that all attributes of an object are null
 * except "__typename" and "id"
 * @param object
 * @returns a boolean
 */
export const hasAllAttrsNull = (object: any) => {
  return Object.keys(object)
    .filter((key) => key !== '__typename' && key !== 'id')
    .every((key) => !object[key])
}

export const hasMedicoes = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes?.some((medicao) => MEDICOES_ATENDIMENTO_PEC.some((key) => !!medicao[key]))

export const getMedicoesPreNatal = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes.filter((medicao) => MEDICOES_PRE_NATAL_PEC.some((key) => !!medicao[key]))

export const convertToMedicaoModel = (medicoes) => medicoes?.map((medicao) => medicao as HistoricoMedicaoModel)

export const convertToEvolucaoProblema = (problemas) => problemas?.map((problema) => problema as EvolucaoProblema)

export const ClassificacaoRiscoColor = {
  PEC_ALTA: red.c40,
  PEC_INTERMEDIARIA: yellow.c60,
  PEC_BAIXA: green.c40,
  PEC_NAO_AGUDA: blue.c40,
  NAO_CLASSIFICADO: gray.c40,
}

export const horaOrTurnoByOrigem = (historicoAtendimento: HistoricoAtendimentoModel) =>
  historicoAtendimento.origemAtendimento === OrigemAtendimento.PEC ||
  historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO
    ? format(historicoAtendimento.dataAtendimento, 'HH:mm')
    : turnoRecord[historicoAtendimento.turno]

export const naoRegistradoNesseAtendimento = 'Não registrado nesse atendimento'

export const shadowIcon = (color: string) =>
  css`
    filter: drop-shadow(0 0.08rem 0.1rem ${color});
  `

export const isTipoAtendimentoIndividual = (
  tipoAtendimento: TipoRegistroHistoricoClinico | TipoAtendimentoProfissional
) =>
  tipoAtendimento === TipoRegistroHistoricoClinico.CONSULTA ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PRE_NATAL ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PUERICULTURA ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PUERPERIO

export const mountInfosAdministrativas = (data): InformacoesAdministrativas => {
  return {
    profissionalPrincipal: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional,
      data.dimensaoCbo,
      data.dimensaoUnidadeSaude,
      data.dimensaoEquipe
    ),
    codigoInep: data.dimensaoInep?.nuIdentificador,
    nomeInep: data.dimensaoInep?.noEstabelecimento,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
  }
}
export const mountInfosAdministrativasAtendimentoCompartilhado = (data): InformacoesAdministrativas => {
  return {
    profissionalPrincipal: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional1,
      data.dimensaoCbo1,
      data.dimensaoUnidadeSaude1,
      data.dimensaoEquipe1
    ),
    profissionalCompartilhado: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional2,
      data.dimensaoCbo2,
      data.dimensaoUnidadeSaude2,
      data.dimensaoEquipe2
    ),
    cpfCnsCuidador: data.cpfCuidador ?? data.cnsCuidador,
    tipoCuidador: data.dimensaoCuidador?.descricao,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
    stAtendimentoCompartilhado: !!data.dimensaoProfissional2?.id,
    profissionalFinalizadorObservacao: dimensoesToInformacoesAdministrativasProfissional(
      data.dimProfFinalizadorObs,
      data.dimCboFinalizadorObs,
      data.dimUbsFinalizadorObs,
      data.dimEquipeFinalizadorObs
    ),
  }
}

export const idadeMarcoAlcancadoStringMount = (anos: number, meses: number) => {
  const anosText = 'ano'.pluralizeAndConcatValue(anos)
  const mesesText = `${meses} ${meses > 1 ? 'meses' : 'mês'}`
  const conectivoAnoMes = anos > 0 ? ' e ' : ''

  return ` (alcançado com ${anos > 0 ? anosText : ''}${meses > 0 ? `${conectivoAnoMes}${mesesText}` : ''})`
}

type HistoricoAtendimentoPec =
  | HistoricoConsultaPecQuery['historicoAtendimentoPec']
  | HistoricoAtendimentoDomiciliarAdQuery['historicoAtendimentoPec']
  | HistoricoPreNatalPecQuery['historicoAtendimentoPec']
  | HistoricoPuericulturaPecQuery['historicoAtendimentoPec']
  | HistoricoPuerperioPecQuery['historicoAtendimentoPec']
  | HistoricoAtendimentoOdontoPecQuery['historicoAtendimentoPec']
  | HistoricoAvaliacaoElegibilidadeAdQuery['historicoAtendimentoPec']
  | HistoricoEscutaInicialQuery['historicoAtendimentoPec']
  | HistoricoVacinacaoPecQuery['historicoVacinacaoPec']['atendimentoProfissional']
  | EscutaInicialDetailQuery['escutaInicialDetail']

export const mountInfosAdministrativasAtendimentosRecentes = (
  data: HistoricoAtendimentoPec
): InformacoesAdministrativas => {
  const lotacaoFinalizador =
    data.atendimentoProfissionalObservacao?.atendimentoObservacao?.atendimentoProfissionalFinalizador?.lotacao
  return {
    profissionalPrincipal: lotacaoToInformacoesAdministrativasProfissional(data.lotacao),

    nomeEstagiario: data.estagio?.profissional?.nome,
    cboEstagiario: data.estagio?.cbo?.nome,
    nomeEquipeEstagiario: data.estagio?.equipe?.nome,
    ineEquipeEstagiario: data.estagio?.equipe?.ine,

    stAtendimentoCompartilhado: !!data.lotacaoAtendimentoCompartilhado?.profissional?.id,
    profissionalCompartilhado: lotacaoToInformacoesAdministrativasProfissional(data.lotacaoAtendimentoCompartilhado),

    localAtendimento: data.atendimento?.localAtendimento?.localAtendimentoExibicao,
    stRegistroTardio: data.atendimento?.isRegistroTardio,
    dataCriacaoRegistro: data.atendimento?.dataCriacaoRegistro,

    profissionalFinalizadorObservacao: lotacaoToInformacoesAdministrativasProfissional(lotacaoFinalizador),
  }
}

const dimensoesToInformacoesAdministrativasProfissional = (
  dimensaoProfissional,
  dimensaoCbo,
  dimensaoUnidadeSaude,
  dimensaoEquipe
): InformacoesAdministrativasProfissional => {
  return {
    nome: dimensaoProfissional?.nome,
    cbo: dimensaoCbo?.nome,
    nomeUnidadeSaude: dimensaoUnidadeSaude?.nome,
    cnesUnidadeSaude: dimensaoUnidadeSaude?.cnes,
    nomeEquipe: dimensaoEquipe?.nome,
    ineEquipe: dimensaoEquipe?.ine,
  }
}

export const lotacaoToInformacoesAdministrativasProfissional = (lotacao): InformacoesAdministrativasProfissional => {
  return (
    lotacao && {
      nome: lotacao.profissional?.nome,
      cbo: lotacao.cbo?.nome,
      nomeUnidadeSaude: lotacao.unidadeSaude?.nome,
      cnesUnidadeSaude: lotacao.unidadeSaude?.cnes,
      nomeEquipe: lotacao.equipe?.nome,
      ineEquipe: lotacao.equipe?.ine,
    }
  )
}

export const isFichaAtendimento = (origemAtendimento: OrigemAtendimento) =>
  origemAtendimento === OrigemAtendimento.CDS ||
  origemAtendimento === OrigemAtendimento.SISTEMA_TERCEIRO ||
  origemAtendimento === OrigemAtendimento.RAS_PEC

export const vacinadoExterior = (aplicadoExterior: boolean) =>
  isUndefinedOrNull(aplicadoExterior) ? '-' : aplicadoExterior ? 'Sim' : 'Não'

export const convertOpcaoMCAEnumToString = (marcadorConsumoAlimentar: MarcadorConsumoAlimentar) => {
  const respostasMap = new Map<PerguntasQuestionarioEnum, string>()

  marcadorConsumoAlimentar.respostas.forEach((resposta) => {
    const respostaMCA = resposta.opcoesSelecionadas
      ?.map((opcao) => OpcoesMarcadoresConsumoAlimentarRecord[opcao])
      .join(', ')

    respostasMap.set(resposta.pergunta, respostaMCA)
  })

  return respostasMap
}
