import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import { useAbsenteismosLazyQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO } from '../common/model'
import { RelatoriosGerenciaisTableWrapper } from '../components/RelatoriosGerenciaisTableWrapper'
import { Absenteismo } from './Absenteismo'
import { absenteismoKeyMapping } from './absenteismoKeyMapping'

const NAME = 'Relatório gerencial de absenteísmo'

export function AbsenteismoView() {
  const [getAbsenteismos, { loading, data }] = useAbsenteismosLazyQuery({
    fetchPolicy: 'cache-first',
  })

  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

  const handleSubmit = useCallback(
    (dateRange: DateRange) => {
      setDateRangeFilter(dateRange)
      getAbsenteismos({
        variables: {
          input: { dataInicio: dateRange.startDate, dataFim: dateRange.endDate },
        },
      })
    },
    [getAbsenteismos]
  )

  const isDataNotUndefinedOrNull = !isUndefinedOrNull(data?.absenteismos)
  const isEmpty = isDataNotUndefinedOrNull && data?.absenteismos.isEmpty()

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório de absenteísmo apresenta a contagem dos agendamentos em que o cidadão não compareceu ou não
            aguardou.
          </Alert>

          <RelatoriosGerenciaisTableWrapper loading={loading} isEmpty={isEmpty} onSubmit={handleSubmit}>
            {isDataNotUndefinedOrNull && (
              <AbsenteismoTable data={data.absenteismos} dateRangeFilter={dateRangeFilter} />
            )}
          </RelatoriosGerenciaisTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}

function AbsenteismoTable(props: { data: Absenteismo[]; dateRangeFilter: DateRange }) {
  const { data, dateRangeFilter } = props
  const {
    meta,
    treeBuilderStatus: { isBuilding },
    ...rest
  } = useTreeBuilder<Absenteismo>({
    keyMapping: absenteismoKeyMapping,
    data: data,
  })

  if (meta?.keyValues && meta?.numberKeys) {
    return (
      <PivotTable<Absenteismo>
        meta={meta}
        keyMapping={absenteismoKeyMapping}
        title={NAME}
        dateRangeFilter={dateRangeFilter}
        isBuilding={isBuilding}
        {...rest}
      />
    )
  } else {
    return null
  }
}
