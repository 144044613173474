/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Text, TextProps, Tooltip } from 'bold-ui'
import { MaxWidthProperty } from 'csstype'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useRef } from 'react'

interface MaxWidthProps {
  maxWidth?: MaxWidthProperty<any>
  showTooltip?: boolean
}

interface MaxLineProps {
  maxLines?: number
}

export interface PartialEllipsisProps extends TextProps {
  title?: string
  tooltipStyle?: ExternalStyles
}

type EllipsisProps = PartialEllipsisProps & (MaxWidthProps | MaxLineProps)

export function Ellipsis(props: EllipsisProps) {
  const { title, children, style, tooltipStyle, maxLines = 3, maxWidth = '100%', showTooltip = true, ...rest } = props

  const { root } = isMaxLineProps(props) ? createLineLimitStyles(maxLines) : createWidthLimitStyles(maxWidth)

  const actualTitle = title || (typeof children === 'string' ? children : undefined)
  const ref = useRef<HTMLDivElement>()
  const isOverflowing = useIsOverflowing(ref, isMaxLineProps(props) ? 'vertical' : 'horizontal')
  return (
    <Tooltip text={isOverflowing && showTooltip && actualTitle} style={tooltipStyle}>
      <div css={root} ref={ref}>
        <Text {...rest}>{children}</Text>
      </div>
    </Tooltip>
  )
}

const createLineLimitStyles = (lineLimit: number) => ({
  root: css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lineLimit};
    overflow: hidden;
  `,
})

const createWidthLimitStyles = (maxWidth: MaxWidthProperty<any>) => ({
  root: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: ${maxWidth};
  `,
})

const isMaxLineProps = (x: any): x is MaxLineProps => x.maxLines
