/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { InfoIcon } from 'components/InfoIcon'
import { ProfissionalParticipanteAgendamentoModel } from 'view/agenda/model-agenda'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'
import { VideochamadaUrlTextInput } from 'view/videochamada/componentes/VideochamadaUrlTextInput'

interface DetalhesAgendamentoCompartilhadoProps {
  profissionalParticipante: ProfissionalParticipanteAgendamentoModel
  videochamadaUuid?: string
}
export function DetalhesAgendamentoCompartilhado(props: DetalhesAgendamentoCompartilhadoProps) {
  const { profissionalParticipante, videochamadaUuid } = props

  return (
    <VFlow vSpacing={0}>
      <HLabel title='Profissional convidado:'>
        {lotacaoAgendamentoToString({
          nomeProfissional: profissionalParticipante.nome,
          nomeCbo: profissionalParticipante.nomeCbo,
          ineEquipe: profissionalParticipante.ineEquipe,
          nomeEquipe: profissionalParticipante.nomeEquipe,
        })}
      </HLabel>
      <HFlow hSpacing={2}>
        {!!profissionalParticipante.email && (
          <HFlow hSpacing={0.2} alignItems='center'>
            <HLabel title='E-mail do convidado:'>{profissionalParticipante.email}</HLabel>
            {profissionalParticipante.email?.includes('*') && (
              <InfoIcon icon='infoCircleFilled' text='E-mail ofuscado em concordância com as exigências da LGPD' />
            )}
          </HFlow>
        )}
        {profissionalParticipante.estabelecimento && (
          <HLabel title='Estabelecimento:'>{profissionalParticipante.estabelecimento}</HLabel>
        )}
      </HFlow>
      {!!videochamadaUuid && (
        <div css={styles.textWrapper}>
          <Text fontWeight='bold'>Consulta via videochamada e-SUS APS</Text>
          <VideochamadaUrlTextInput videochamadaUuid={videochamadaUuid} />
        </div>
      )}
    </VFlow>
  )
}

const styles = {
  textWrapper: css`
    margin-top: 0.5rem;
  `,
}
