import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings } from '../common/KeyMapping'
import { AtendimentoProfissional } from './AtendimentoProfissional'

const tipoAtendimento: KeyMapping = {
  keyName: 'Tipo de atendimento',
  formatter: (s: string) => s.titleCase(),
}

const origemAtendimento: KeyMapping = {
  keyName: 'Origem do atendimento',
  formatter: (s: string) => s.capitalize(),
}

const tipoConsulta: KeyMapping = {
  keyName: 'Tipo de consulta',
  formatter: (s: string) => s.titleCase(),
}

const atendimentoCompartilhadoLotacao: KeyMapping = {
  keyName: 'Atendimento compartilhado',
}

export const atendimentoProfissionalKeyMapping: PivotTableProps<AtendimentoProfissional>['keyMapping'] = new Map<
  keyof AtendimentoProfissional,
  KeyMapping
>([
  ['uf', keyMappings.uf],
  ['municipio', keyMappings.municipio],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['equipe', keyMappings.equipe],
  ['tipoAtendimento', tipoAtendimento],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['origemAtendimento', origemAtendimento],
  ['turno', keyMappings.turno],
  ['mes', keyMappings.mes],
  ['sexo', keyMappings.sexo],
  ['dia', keyMappings.dia],
  ['diaDaSemana', keyMappings.diaDaSemana],
  ['hora', keyMappings.hora],
  ['tipoConsulta', tipoConsulta],
  ['atendimentoCompartilhadoLotacao', atendimentoCompartilhadoLotacao],
  ['categoriaProfissional', keyMappings.categoriaProfissional],
  ['semana', keyMappings.semana],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['cbo', keyMappings.cbo],
])
