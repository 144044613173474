/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { Accordion } from 'react-accessible-accordion'

import { NewsMenuAccordionItem } from '../accordion/NewsMenuAccordionItem'
import useNewsItems, { NewsVersion } from '../hooks/useNewsItems'
import { NewsSideMenuHeader } from './NewsSideMenuHeader'
import { SideMenuNewsSublink } from './SideMenuNewsSublink'
import { useCalculaCabecalhoSideMenuNews } from './useCalculaCabecalhoSideMenuNews'

const ASIDE_HEIGHT_DIFF = Math.max(0, 6.1 / 16) + 1.5

interface SideMenuNewsProps {
  currentVersion: string
  selectedVersion: string
  onItemClick: (version: NewsVersion) => void
}

export const SideMenuNews = (props: SideMenuNewsProps) => {
  const { currentVersion, onItemClick, selectedVersion } = props
  const items = useNewsItems()
  const passouCabecalho = useCalculaCabecalhoSideMenuNews()
  const theme = useTheme()
  const styles = createStyles(theme, passouCabecalho)
  const {
    PILOTO_OBSERVACAO_1_ENABLED,
    TERRITORIO_ENABLED,
    ENVIO_RNDS_ENABLED,
    VISUALIZACAO_TERRITORIO_ENABLED,
    PILOTO_HISTORICO_CUIDADO_COMPARTILHADO_1_ENABLED,
    RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED,
  } = useFlags()

  // Remover a constante da linha de baixo quando for remover a flag 'PILOTO_OBSERVACAO_1_ENABLED'
  const flaggedHashObservacao = !PILOTO_OBSERVACAO_1_ENABLED && '#observacao-5-1'
  // Remover a constate da linha de baixo quando for remover a flag 'TERRITORIO_ENABLED'
  const flaggedHashTerritorio = !TERRITORIO_ENABLED && '#reterritorializacao-5-1'
  // Remover a constate da linha de baixo quando for remover a flag 'ENVIO_RNDS_ENABLED'
  const flaggedHashEnvioRnds = !ENVIO_RNDS_ENABLED && '#rnds-vacina-rpm-5-2'
  // Remover a constate da linha de baixo quando for remover a flag 'VISUALIZACAO_TERRITORIO_ENABLED'
  const flaggedHashVisualizacaoTerritorio = !VISUALIZACAO_TERRITORIO_ENABLED && '#acompanhamento-territorio-5-2'
  // Remover a constate da linha de baixo quando for remover a flag 'PILOTO_HISTORICO_CUIDADO_COMPARTILHADO_1_ENABLED'
  const flaggedHashHistoricoCuidadoCompartilhado =
    !PILOTO_HISTORICO_CUIDADO_COMPARTILHADO_1_ENABLED && '#historico-cuidado-compartilhado-5-2'
  // Remover a constante da linha de baixo quando for remover a flag 'RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED'
  const flaggedHashRelatorioGerencialCuidadoCompartilhado =
    !RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED && '#relatorio-gerencial-cuidado-compartilhado-5-2'

  const flaggedHashList = [
    flaggedHashObservacao,
    flaggedHashTerritorio,
    flaggedHashEnvioRnds,
    flaggedHashVisualizacaoTerritorio,
    flaggedHashHistoricoCuidadoCompartilhado,
    flaggedHashRelatorioGerencialCuidadoCompartilhado,
  ]

  return (
    <aside css={styles.aside}>
      <NewsSideMenuHeader />
      <Accordion preExpanded={[currentVersion]}>
        {items.map((item, index) => {
          return (
            <NewsMenuAccordionItem
              title={item.title}
              index={index}
              totalItems={items.length}
              selectedVersion={selectedVersion}
              version={item.version}
              onClick={onItemClick}
            >
              {item.children
                .filter((child) => !flaggedHashList.includes(child.to))
                .map((subItem) => {
                  return <SideMenuNewsSublink to={subItem.to} key={subItem.title} title={subItem.title} />
                })}
            </NewsMenuAccordionItem>
          )
        })}
      </Accordion>
    </aside>
  )
}

const createStyles = (theme: Theme, passouCabecalho: boolean) => ({
  aside: css`
    width: 100%;
    max-width: 350px;
    padding-right: 1.5rem;
    border-right: 1px solid ${theme.pallete.divider};
    position: ${passouCabecalho ? 'fixed' : 'static'};
    top: 1rem;
    padding-left: 0.5rem;
    height: calc(100% - ${ASIDE_HEIGHT_DIFF}rem);
    overflow: hidden auto;

    ${theme.breakpoints.up('xl')} {
      //1920
      width: ${passouCabecalho ? 'calc(100% / 15 * 2.73)' : 'auto'};
    }

    ${theme.breakpoints.down('xl')} {
      //1920
      width: ${passouCabecalho ? 'calc(100% / 15 * 3.18)' : 'auto'};
    }

    ${theme.breakpoints.down('md')} {
      //960
      width: passouCabecalho && 'calc(100% / 15 * 3.30)';
    }

    ${theme.breakpoints.up('lg')} {
      padding-left: 1rem;
    }

    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.pallete.gray.c40};
      border-radius: ${theme.radius.tag};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${theme.pallete.gray.c60};
    }

    &::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
      border-radius: ${theme.radius.tag};
      box-shadow: inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider};
    }
  `,
})
