/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { PopperButton, PopperButtonProps } from 'components/popper'
import { useMemo } from 'react'

import { EventoAgendaLotacao } from '../../model-agenda'
import { DetalhesEventoAgendamento } from './DetalhesEventoAgendamento'

export interface EventoAgendamentoPopperButtonProps extends Omit<PopperButtonProps, 'renderPopper'> {
  event: EventoAgendaLotacao
  isAgendaOnlineAtiva: boolean
}

export function EventoAgendamentoPopperButton({
  event,
  children,
  isAgendaOnlineAtiva,
  style,
  ...rest
}: EventoAgendamentoPopperButtonProps) {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return (
    <PopperButton
      placement='bottom-start'
      kind='primary'
      positioningStrategy='fixed'
      type='button'
      skin='outline'
      style={css(styles.popperButton, style)}
      z-index={1}
      renderPopper={() => <DetalhesEventoAgendamento event={event} isAgendaOnlineAtiva={isAgendaOnlineAtiva} />}
      {...rest}
    >
      {children}
    </PopperButton>
  )
}

const createStyles = (theme: Theme) => ({
  popperButton: css`
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    border-width: 0;
    border-radius: 0;
    outline: none !important;
    padding: 0rem;
    font-size: ${theme.typography.sizes.text};
    color: ${theme.pallete.text.main};
    font-weight: normal;

    & > span {
      height: 100%;
      width: 100%;
    }
    &:not(:disabled):hover {
      background-color: transparent;
    }
    &:not(:disabled):focus {
      box-shadow: none;
    }
  `,
})
