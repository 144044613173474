/* eslint-disable no-console */
import useLoginAnalytics from 'components/auth/useLoginAnalytics'
import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { PecSwitch } from 'components/route'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ServerTimeProvider } from 'hooks/useServerTime'
import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { ForcarAlterarSenhaView } from 'view/alterar-senha/AlterarSenhaView'
import { AutorizarMunicipioRootView } from 'view/autorizacao/AutorizarMunicipioRootView'
import { EscolhaModuloAcessoRootView } from 'view/EscolhaModuloAcessoRootView'
import { SelecaoAcessoLoginView } from 'view/login/SelecaoAcessoView/SelecaoAcessoView'
import { TermosView } from 'view/termos-uso/TermosView'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'
import { SairVideochamadaView } from 'view/videochamada/SairVideochamadaView'
import { VideochamadaEncerradaView } from 'view/videochamada/VideochamadaEncerradaView'
import { VideochamadaPerdaConexaoView } from 'view/videochamada/VideochamadaPerdaConexaoView '
import { VideochamadaRootView } from 'view/videochamada/VideochamadaRootView'
import { VideochamadaTimeOutView } from 'view/videochamada/VideochamadaTimeOutView'

import { LoggedRootView } from '../LoggedRootView'

export function PrivateRootView() {
  const { data, refresh } = useSession()
  const { analytics } = useFirebase()

  const escolhaModulo = () => <EscolhaModuloAcessoRootView refresh={refresh} />
  const autorizarMunicipioView = () => <AutorizarMunicipioRootView acessoId={data.acesso && data.acesso.id} />

  const { log } = useLoginAnalytics()

  const videochamadasConfig = useVideochamadasConfiguration()

  useEffect(() => log(data?.sessionProvider))

  useEffect(() => {
    if (data?.acesso) {
      analytics.setUserId(data.profissional.usuario.hashId)
      analytics.setUserProperties({
        Acesso: data.acesso.tipo,
        Perfis: data.acesso.perfis?.map((p) => p.nome).join(', '),
        CBO: isLotacaoOrEstagio(data.acesso) && data.acesso.cbo.nome,
        Versão: process.env.REACT_APP_VERSION,
        Município: data.acesso.municipio?.nome,
      })
    }
  }, [analytics, data])

  return (
    <ServerTimeProvider>
      <PecSwitch>
        {!data.acesso?.moduloInicial && (
          // Usuário pode acessar termos antes de acesso a lotacao e modulo inicial
          <Route path='/termosUso' component={TermosView} />
        )}

        {!data.profissional.usuario.aceitouTermosUso && (
          // Usuário precisa aceitar os termos de uso
          <Route component={TermosView} />
        )}

        {data.profissional.usuario.trocarSenha && (
          // Usuário precisa alterar senha
          <Route component={ForcarAlterarSenhaView} />
        )}

        {!data.acesso && (
          // Usuário precisa selecionar acesso
          <Route component={SelecaoAcessoLoginView} />
        )}

        {data.acesso &&
          data.acesso.__typename === 'AdministradorMunicipal' &&
          (!data.acesso.autorizado || !data.acesso.habilitado) && <Route component={autorizarMunicipioView} />}

        {data.acesso && !data.acesso.moduloInicial && (
          // Usuário precisa escolher seu modulo Inicial
          <Route component={escolhaModulo} />
        )}

        {videochamadasConfig.videochamadasEnabled && (
          <Route path='/videochamada/encerrada' component={VideochamadaEncerradaView} />
        )}

        {videochamadasConfig.videochamadasEnabled && (
          <Route path='/videochamada/timeout' component={VideochamadaTimeOutView} />
        )}

        {videochamadasConfig.videochamadasEnabled && (
          <Route path='/videochamada/:uuid/perda-conexao' component={VideochamadaPerdaConexaoView} />
        )}

        {videochamadasConfig.videochamadasEnabled && (
          <Route path='/videochamada/:uuid/sair' component={SairVideochamadaView} />
        )}

        {videochamadasConfig.videochamadasEnabled && (
          <Route path='/videochamada/:uuid' component={VideochamadaRootView} />
        )}

        <Route component={LoggedRootView} />
      </PecSwitch>
    </ServerTimeProvider>
  )
}
