import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings } from '../common/KeyMapping'
import { Absenteismo } from './Absenteismo'

const INDISPONIVEL = 'Indisponível'

const nomeSituacaoAgendamento: KeyMapping = {
  keyName: 'Tipo de falta',
  formatter: (s: string) => s.capitalize(),
}

const intervaloAgendamento: KeyMapping = {
  keyName: 'Tempo entre agendamento e consulta',
  formatter: (s: string) => s.capitalize(),
  ordenator: (a: string, b: string) => {
    if (a === INDISPONIVEL) {
      return -1
    } else if (b === INDISPONIVEL) {
      return 1
    } else {
      const num1 = Number(a.split(' ')[0])
      const num2 = Number(b.split(' ')[0])
      return num1 - num2
    }
  },
}

export const absenteismoKeyMapping: PivotTableProps<Absenteismo>['keyMapping'] = new Map<keyof Absenteismo, KeyMapping>(
  [
    ['categoriaProfissional', keyMappings.categoriaProfissional],
    ['nomeEquipe', keyMappings.equipe],
    ['nomeMunicipio', keyMappings.municipio],
    ['nomeProfissional', keyMappings.nomeProfissional],
    ['nomeSituacaoAgendamento', nomeSituacaoAgendamento],
    ['nomeUnidadeSaude', keyMappings.unidadeSaude],
    ['turno', keyMappings.turno],
    ['intervaloAgendamento', intervaloAgendamento],
    ['faixaEtaria', keyMappings.faixaEtaria],
    ['nomeCbo', keyMappings.cbo],
  ]
)
