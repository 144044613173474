import { VFlow } from 'bold-ui'
import React, { useState } from 'react'
import { DiscussaoCasoPageContent } from 'view/cuidados-compartilhados/components/DiscussaoCasoPageContent'
import {
  CidadaoCuidadoCompartilhado,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoFormModel,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoPanel } from '../components/CuidadoCompartilhadoPanel'
import { DiscussaoCasoForm } from './DiscussaoCasoForm'

interface DiscussaoCasoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  cidadao: CidadaoCuidadoCompartilhado
  headerHeight: number
  responsavelCuidado: ResponsavelCuidadoCompartilhado
  cacheState: DiscussaoCasoFormModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  updateCacheState: (values: DiscussaoCasoFormModel) => void
  handleSubmit: (values: DiscussaoCasoFormModel) => Promise<void>
  handleCancel: () => void
}

export const DiscussaoCasoView = (props: DiscussaoCasoViewProps) => {
  const {
    cuidadoCompartilhado,
    cidadao,
    headerHeight,
    responsavelCuidado,
    cacheState,
    videochamadaUuidState,
    updateCacheState,
    handleCancel,
    handleSubmit,
  } = props

  const [initialValues] = useState(cacheState) // usado pra setar os valores iniciais do Form apenas na primeira vez que renderizar

  return (
    <DiscussaoCasoPageContent cuidadoCompartilhado={cuidadoCompartilhado} cidadao={cidadao} headerHeight={headerHeight}>
      <VFlow>
        <CuidadoCompartilhadoPanel cuidadoCompartilhado={cuidadoCompartilhado} />

        <DiscussaoCasoForm
          cuidadoCompartilhado={cuidadoCompartilhado}
          responsavelCuidado={responsavelCuidado}
          initialValues={initialValues}
          videochamadaUuidState={videochamadaUuidState}
          updateCacheState={updateCacheState}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </VFlow>
    </DiscussaoCasoPageContent>
  )
}
