import {
  AtencaoDomiciliarCidadaoInput,
  AtencaoDomiciliarCondutaDesfecho,
  AtencaoDomiciliarModalidade,
  AtencaoDomiciliarOrigem,
  CidadaoAdLoadQuery,
  RelacaoCidadaoCuidador,
} from 'graphql/types.generated'

import CidadaoADFormModel from './components/CidadaoADFormModel'
import { CidadaoListaAdSelectFieldModel } from './selectFields/model-ad'

export default (model: CidadaoADFormModel): AtencaoDomiciliarCidadaoInput => ({
  id: model.id,
  cidadaoId: model.cidadao?.id,
  dataAdmissao: model.dataAdmissao,
  modalidade: model.modalidade as AtencaoDomiciliarModalidade,
  procedencia: model.procedencia as AtencaoDomiciliarOrigem,
  cid10PrincipalId: model.cid10Principal?.id,
  procedenciaEspecificacao: model.especifique,
  cid10Secundario1Id: model.cid10Secundario1?.id,
  cid10Secundario2Id: model.cid10Secundario2?.id,
  condutaDesfecho: model.condutaDesfecho as AtencaoDomiciliarCondutaDesfecho,
  dataSaida: model.dataSaida,
  numeroDO: model.numeroObito,
  dataObito: model.dataObito,
  cidadaoAdEmObitoId: model.cidadaoAdEmObito?.id,
  nomeResponsavel: model.responsavel?.nomeResponsavel,
  dataNascimentoResponsavel: model.responsavel?.dataNascimento,
  cpfCnsResponsavel: model.responsavel?.cpfOuCns,
  naoPossuiCuidador: model.cuidador?.naoPossuiCuidador,
  nomeCuidador: model.cuidador?.naoPossuiCuidador === false ? model.cuidador?.nomeCuidador : null,
  dataNascimentoCuidador: model.cuidador?.naoPossuiCuidador === false ? model.cuidador?.dataNascimento : null,
  cpfCnsCuidador: model.cuidador?.naoPossuiCuidador === false ? model.cuidador?.cpfOuCns : null,
  relacaoCidadaoCuidador:
    model.cuidador.naoPossuiCuidador === false ? (model.cuidador?.relacao as RelacaoCidadaoCuidador) : null,
})

export function convertToForm(cidadaoAd: CidadaoAdLoadQuery['cidadaoAD']): CidadaoADFormModel {
  const { ...rest } = cidadaoAd
  return {
    ...rest,
    id: cidadaoAd?.id,
    cidadao: {
      ...cidadaoAd?.cidadao,
      id: cidadaoAd?.cidadao?.id,
      nome: cidadaoAd?.cidadao?.nome,
      dataNascimento: cidadaoAd?.cidadao?.dataNascimento,
      sexo: cidadaoAd?.cidadao?.sexo,
      identidadeGeneroDbEnum: cidadaoAd?.cidadao?.identidadeGeneroDbEnum,
    },
    dataAdmissao: cidadaoAd?.dataAdmissao,
    modalidade: cidadaoAd?.modalidade,
    procedencia: cidadaoAd?.procedencia,
    especifique: cidadaoAd?.procedenciaEspecificacao,
    cid10Principal: {
      ...cidadaoAd?.cid10Principal,
      id: cidadaoAd?.cid10Principal?.id,
    },
    condutaDesfecho: cidadaoAd?.condutaDesfecho,
    dataSaida: cidadaoAd?.dataSaida,
    dataObito: cidadaoAd?.dataObito,
    numeroObito: cidadaoAd?.numeroDocumentoObito,
    cidadaoAdEmObito: {
      id: cidadaoAd?.cidadaoAdEmObito?.id,
      cidadao: {
        ...cidadaoAd?.cidadaoAdEmObito?.cidadao,
        id: cidadaoAd?.cidadaoAdEmObito?.cidadao?.id,
        nome: cidadaoAd?.cidadaoAdEmObito?.cidadao?.nome,
        dataNascimento: cidadaoAd?.cidadaoAdEmObito?.cidadao?.dataNascimento,
      },
    },
    responsavel: {
      nomeResponsavel: cidadaoAd?.cidadao?.nomeResponsavel,
      dataNascimento: cidadaoAd?.cidadao?.dtNascimentoResponsavel,
      cpfOuCns: cidadaoAd?.cidadao?.cpfCnsResponsavel,
    },
    cuidador: {
      nomeCuidador: cidadaoAd?.cidadao?.nomeCuidador,
      dataNascimento: cidadaoAd?.cidadao?.dtNascimentoCuidador,
      cpfOuCns: cidadaoAd?.cidadao?.cpfCnsCuidador,
      naoPossuiCuidador: cidadaoAd?.cidadao?.naoPossuiCuidador,
      relacao: cidadaoAd?.cidadao?.tipoCuidador,
    },
  }
}
export function convertToFormPosObito(cidadaoAd: CidadaoAdLoadQuery['cidadaoAD']): CidadaoADFormModel {
  const { condutaDesfecho, id, cidadao } = cidadaoAd

  return {
    cidadao: null,
    dataAdmissao: null,
    modalidade:
      condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO ? AtencaoDomiciliarModalidade.POS_OBITO : null,
    cid10Principal: null,
    procedencia: null,
    condutaDesfecho: AtencaoDomiciliarCondutaDesfecho.PERMANENCIA,
    cidadaoAdEmObito: {
      id: id,
      cidadao: {
        id: cidadao?.id,
        nome: cidadao?.nome,
        dataNascimento: cidadao?.dataNascimento,
        dataObito: cidadao?.dataObito,
      },
    },
    cuidador: { naoPossuiCuidador: false },
  }
}

export function convertToFormCadastroCidadao(cidadao: CidadaoListaAdSelectFieldModel): CidadaoADFormModel {
  return {
    cidadao: {
      ...cidadao,
      id: cidadao?.id,
      nome: cidadao?.nome,
      dataNascimento: cidadao?.dataNascimento,
      sexo: cidadao?.sexo,
      identidadeGeneroDbEnum: cidadao?.identidadeGeneroDbEnum,
    },
    dataAdmissao: null,
    modalidade: null,
    cid10Principal: null,
    cid10Secundario1: null,
    cid10Secundario2: null,
    condutaDesfecho: AtencaoDomiciliarCondutaDesfecho.PERMANENCIA,
    cidadaoAdEmObito: null,
    cuidador: { naoPossuiCuidador: false },
  }
}
