import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { useRocksdbEnabledQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { AbsenteismoView } from './absenteismo/AbsenteismoView'
import { AtendimentosProfissionalView } from './atendimentos/AtendimentosProfissionaisView'
import { RelatorioGerencialCuidadoCompartilhadoView } from './cuidado-compartilhado/RelatorioGerencialCuidadoCompartilhadoView'
import { VacinacaoView } from './vacinacao/VacinacaoView'

export function BiRootView(props: any) {
  const { path } = useRouteMatch()
  const { data, loading } = useRocksdbEnabledQuery()

  const { RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED } = useFlags()

  if (loading) {
    return <PageLoading message='Carregando' />
  }

  const rocksdbEnabled = data.info?.rocksdbEnabled

  return (
    <>
      <PecSwitch>
        {rocksdbEnabled && (
          <PrivateRoute exact path={`${path}/atendimentos`} permission={Permissions.relatorios.gerenciais.atendimentos}>
            <AtendimentosProfissionalView />
          </PrivateRoute>
        )}
        <PrivateRoute exact path={`${path}/absenteismo`} permission={Permissions.relatorios.gerenciais.absenteismo}>
          <AbsenteismoView />
        </PrivateRoute>

        {RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO_ENABLED && (
          <PrivateRoute
            exact
            path={`${path}/cuidado-compartilhado`}
            permission={Permissions.relatorios.gerenciais.cuidadoCompartilhado}
          >
            <RelatorioGerencialCuidadoCompartilhadoView />
          </PrivateRoute>
        )}
        {rocksdbEnabled && (
          <PrivateRoute exact path={`${path}/vacinacao`} permission={Permissions.relatorios.gerenciais.vacinacao}>
            <VacinacaoView />
          </PrivateRoute>
        )}
      </PecSwitch>
    </>
  )
}
