/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, HFlow, Theme, Tooltip, useTheme } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { CheckboxField } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { PageContent } from 'components/layout/PageContent'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useEffect } from 'react'
import { FormSpy, useForm, useFormState } from 'react-final-form'

import { meta, SoapState } from '../atendimento-individual/model'
import { computeFinalizarAtendimentoButtonLabel } from '../utils/computeFinalizarAtendimentoButtonLabel'
import { ManterCidadaoLista } from './soap/finalizacao/components/DesfechoAtendimentoPanel'

interface AtendimentoIndividualFooterProps {
  nomeAtendimento: string
  handleSubmit: () => void
  handleCancel: (isStartObservacao: boolean) => void
  cancelButtonLabel: string
}

export const AtendimentoIndividualFooter = (props: AtendimentoIndividualFooterProps) => {
  const { nomeAtendimento, handleSubmit, handleCancel, cancelButtonLabel } = props
  const theme = useTheme()
  const classes = createStyles(theme)
  const { analytics } = useFirebase()

  const {
    mutators: { changeValue },
  } = useForm<SoapState>()

  const { values: formValues } = useFormState({ subscription: { values: true } })

  const {
    observacao: { isAtendimentoObservacao },
    statusRevisaoAtendimento,
  } = useAtendimentoContext()

  const isStartObservacao = resolveValue(formValues, meta.plano?.startObservacao)

  const manterCidadaoNaLista = resolveValue(formValues, meta.finalizacao?.desfechoAtendimento?.manterCidadaoLista)

  const imprimirAtendimento = resolveValue(formValues, meta.imprimirAtendimento)

  const isFinalizandoObservacao =
    (isStartObservacao || isAtendimentoObservacao) && manterCidadaoNaLista === ManterCidadaoLista.NAO

  const { isEstagio } = useSession()

  const submitButtonLabel = computeFinalizarAtendimentoButtonLabel({
    isEstagio,
    statusRevisaoAtendimento,
    tipoAtendimentoText: nomeAtendimento,
    isFinalizandoObservacao,
    isStartingObservacao: isStartObservacao,
    isAtendimentoObservacao,
  })

  const desabilitarImpressaoFinalizacao =
    manterCidadaoNaLista === ManterCidadaoLista.SIM && (isStartObservacao || isAtendimentoObservacao)

  useEffect(() => {
    if (desabilitarImpressaoFinalizacao && imprimirAtendimento) {
      changeValue(meta.imprimirAtendimento, false)
    }
  }, [changeValue, desabilitarImpressaoFinalizacao, imprimirAtendimento])

  return (
    <div css={classes.actions}>
      <PageContent type='filled' fluid>
        <HFlow justifyContent='space-between'>
          <FormSpy<SoapState> subscription={{ dirty: true, active: true, values: true }}>
            {({ values }) => (
              <Alert inline type='success' styles={{ wrapper: classes.draftAlertWrapper }}>
                Rascunho salvo automaticamente às <DateTime format='HH:mm' value={values.lastSaved} />
              </Alert>
            )}
          </FormSpy>
          <HFlow>
            {!isEstagio && (
              <Tooltip
                text={
                  desabilitarImpressaoFinalizacao &&
                  'A impressão do atendimento de observação só pode ocorrer ao liberar o cidadão.'
                }
              >
                <CheckboxField
                  name={meta.imprimirAtendimento}
                  label='Imprimir atendimento ao finalizar'
                  disabled={desabilitarImpressaoFinalizacao}
                  style={classes.print}
                  onClick={() => analytics.logEvent('click_imprimir_finalizar')}
                />
              </Tooltip>
            )}
            <Button kind='normal' onClick={() => handleCancel(isStartObservacao)}>
              {cancelButtonLabel}
            </Button>
            <Button kind='primary' onClick={handleSubmit}>
              {submitButtonLabel}
            </Button>
          </HFlow>
        </HFlow>
      </PageContent>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  draftAlertWrapper: css`
    background-color: transparent;
    border-width: 0;
  `,
  actions: css`
    border-top: 1px solid ${theme.pallete.divider};
    height: 6.125rem;
  `,
  print: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
    padding: 0.75rem 1rem;
  `,
})
