/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Button, Cell, DateRange, Grid, Heading, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import {
  CheckboxField,
  EquipeSelectField,
  Form,
  FormDebouncedValueSpy,
  FormRenderProps,
  SubmitButton,
  SwitchField,
} from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { useFlags } from 'config/useFlagsContext'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { StatusAtendimento, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { convertDateRangeToInstantRange } from 'util/date/dateRange'
import { metaPath } from 'util/metaPath'

import { statusAtendimentoStyles } from '../listaAtendimentoStyles'
import { STATUS_ATENDIMENTO_FINALIZADO } from '../model'
import { ListaAtendimentoFilterPopperModel } from './model'

export interface ListaAtendimentoFilterPopperFormModel extends Omit<ListaAtendimentoFilterPopperModel, 'periodo'> {
  periodo: DateRange
}

const path = metaPath<ListaAtendimentoFilterPopperFormModel>()

const filterFormDecorator = (defaultPeriodo: DateRange, isUpa: boolean) => {
  return createDecorator(
    {
      field: path.isSomenteNaoFinalizados.absolutePath(),
      updates: {
        [path.statusAtendimento.absolutePath()]: (value: boolean, allValues: ListaAtendimentoFilterPopperFormModel) =>
          value && allValues.statusAtendimento?.length > 0
            ? allValues.statusAtendimento.filter((item) => !STATUS_ATENDIMENTO_FINALIZADO.includes(item))
            : allValues.statusAtendimento,
        [path.periodo.absolutePath()]: (
          value: boolean,
          allValues: ListaAtendimentoFilterPopperFormModel,
          prevValues: ListaAtendimentoFilterPopperFormModel
        ) => {
          return value || allValues.isPeriodo24h
            ? { startDate: null, endDate: null }
            : prevValues.isSomenteNaoFinalizados
            ? defaultPeriodo
            : allValues.periodo
        },
        [path.isPeriodo24h.absolutePath()]: (
          value: boolean,
          allValues: ListaAtendimentoFilterPopperFormModel,
          prevValues: ListaAtendimentoFilterPopperFormModel
        ) => {
          if (value || !isUpa) {
            return false
          } else if (prevValues.isSomenteNaoFinalizados) {
            return true
          } else {
            return allValues.isPeriodo24h
          }
        },
      },
    },
    {
      field: path.isPeriodo24h.absolutePath(),
      updates: {
        [path.periodo.absolutePath()]: (
          value: boolean,
          allValues: ListaAtendimentoFilterPopperFormModel,
          oldValues: ListaAtendimentoFilterPopperFormModel
        ) => {
          if (isEmpty(oldValues) || !isUpa) return allValues.periodo

          return value || allValues.isSomenteNaoFinalizados ? { startDate: null, endDate: null } : defaultPeriodo
        },
      },
    }
  )
}

interface ListaAtendimentoPopperProps {
  defaultPeriodo: DateRange
  initialValues?: ListaAtendimentoFilterPopperFormModel
  isDefaultFilter?: boolean
  onFechar(): void
  onSubmit(values: ListaAtendimentoFilterPopperFormModel, formApi: FormApi<ListaAtendimentoFilterPopperFormModel>): void
  onClear(): void
  onChangeFilter(values: ListaAtendimentoFilterPopperModel): void
}

export function ListaAtendimentoFilterPopperForm(props: ListaAtendimentoPopperProps) {
  const { defaultPeriodo, initialValues, isDefaultFilter, onSubmit, onFechar, onClear, onChangeFilter } = props

  const { UPA_ENABLED } = useFlags()
  const { analytics } = useFirebase()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const unidadeSaude = acesso?.unidadeSaude
  const isCEO = unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const isUpa = UPA_ENABLED && unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

  const decorator = useMemo(() => [filterFormDecorator(defaultPeriodo, isUpa)], [defaultPeriodo, isUpa])

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_popper_LA')
  }

  const renderForm = (formProps: FormRenderProps<ListaAtendimentoFilterPopperFormModel>) => {
    const isSomenteNaoFinalizados = formProps.values.isSomenteNaoFinalizados
    const isPeriodo24h = isUpa && formProps.values.isPeriodo24h

    return (
      <Box
        style={css`
          width: 42rem;
          margin-top: 1rem;
          margin-right: -0.1875rem;
        `}
      >
        <FormDebouncedValueSpy
          onChange={(formState) =>
            onChangeFilter({ ...formState.values, periodo: convertDateRangeToInstantRange(formState.values.periodo) })
          }
          wait={0}
        />

        <Grid gapVertical={1} gap={1} justifyContent='flex-end'>
          <Cell size={12}>
            <Grid gapVertical={0.5}>
              <Cell size={12}>
                <Heading level={5}>Status do atendimento</Heading>
              </Cell>
              <Cell size={12} style={{ marginLeft: '-0.25rem' }}>
                <Grid gap={0} gapVertical={0.5}>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterCheckboxLabelRender(
                        'Aguardando atendimento',
                        statusAtendimentoStyles.aguardandoAtendimentoBox
                      )}
                      value={StatusAtendimento.AGUARDANDO_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterCheckboxLabelRender('Em atendimento', statusAtendimentoStyles.emAtendimentoBox)}
                      value={StatusAtendimento.EM_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterCheckboxLabelRender('Em escuta inicial', statusAtendimentoStyles.emEscutaInicialBox)}
                      value={StatusAtendimento.EM_ESCUTA_INICIAL}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterCheckboxLabelRender(
                        'Atendimento realizado',
                        statusAtendimentoStyles.atendimentoRealizadoBox
                      )}
                      value={StatusAtendimento.ATENDIMENTO_REALIZADO}
                      disabled={isSomenteNaoFinalizados}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterCheckboxLabelRender('Não aguardou', statusAtendimentoStyles.naoAguardouBox)}
                      value={StatusAtendimento.NAO_AGUARDOU}
                      disabled={isSomenteNaoFinalizados}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
          <Cell size={isUpa ? 6 : 12}>
            <SwitchField name={path.isSomenteNaoFinalizados} label='Ver somente os atendimentos não finalizados' />
          </Cell>
          {isUpa && (
            <Cell size={6}>
              <SwitchField name={path.isPeriodo24h} label='Últimas 24h' disabled={isSomenteNaoFinalizados} />
            </Cell>
          )}

          <Cell size={6}>
            <HFlow alignItems='flex-end'>
              <DateRangeField
                name={path.periodo}
                label='Período'
                maxDate={defaultPeriodo.endDate}
                disabled={isSomenteNaoFinalizados || isPeriodo24h}
              />
            </HFlow>
          </Cell>
          <Cell size={6}>
            <TipoServicoSelectField label='Tipo serviço' name={path.tiposServico} multiple includeInterno />
          </Cell>
          {!isCEO && (
            <Cell size={6}>
              <EquipeSelectField label='Equipe' name={path.equipes} unidadeSaudeId={unidadeSaude.id} multiple />
            </Cell>
          )}
          <Cell size={isCEO ? 12 : 6}>
            <LotacaoAndEstagioSelectField
              label='Profissional'
              name={path.responsaveis}
              unidadeSaudeId={unidadeSaude.id}
              multiple
              includeEstagios
            />
          </Cell>

          <Cell alignSelf='flex-end'>
            <Button onClick={onFechar} size='small' skin='outline' kind='normal'>
              Fechar
            </Button>
          </Cell>
          <Cell alignSelf='flex-end'>
            <Tooltip text='Redefinir filtros para o padrão'>
              <Button kind='normal' size='small' onClick={handleOnClear} disabled={isDefaultFilter}>
                <Icon style={{ paddingRight: '0.5rem' }} icon='redo' />
                Voltar para padrão
              </Button>
            </Tooltip>
          </Cell>
          <Cell alignSelf='flex-end'>
            <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
              Filtrar
            </SubmitButton>
          </Cell>
        </Grid>
      </Box>
    )
  }
  return (
    <Form<ListaAtendimentoFilterPopperFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onSubmitSucceeded={onFechar}
      decorators={decorator}
    />
  )
}

export const filterCheckboxLabelRender = (nome: string, cssStyles: SerializedStyles) => (
  <HFlow hSpacing={0} justifyContent='center' alignItems='center'>
    <div
      css={css(
        cssStyles.styles,
        css`
          width: 0.5rem;
          height: 1.5rem;
          border-radius: 0.125rem;
          margin-right: 0.5rem;
        `
      )}
    />
    {nome}
  </HFlow>
)
