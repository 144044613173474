/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Spinner, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { usePecField } from 'components/form/final-form/hooks/useField'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { usePrescricaoDigitalEnabledQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Dispatch, memo, SetStateAction, useEffect } from 'react'
import { Meta } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'

import { EmailUpdateForm } from '../../components/EmailUpdateForm'
import { AtestadoAssinaturaDigitalGerarCheckbox } from './AtestadoAssinaturaDigitalGerarCheckbox'

interface AtestadoAssinaturaDigitalViewProps {
  name: Meta<boolean>
  cidadaoId: ID
  cidadaoEmail: string
  onEmailCidadaoChange: Dispatch<SetStateAction<boolean>>
  hasCid10?: boolean
  style?: ExternalStyles
  onDirtyChange: (dirty: boolean) => void
  canceled?: boolean
  onCancel?: (canceled: boolean) => void
}

export const AtestadoAssinaturaDigitalView = memo((props: AtestadoAssinaturaDigitalViewProps) => {
  const {
    name,
    cidadaoId,
    cidadaoEmail,
    onEmailCidadaoChange,
    hasCid10 = false,
    style = styles.externalStylesDefault,
    onDirtyChange,
    canceled = false,
    onCancel,
  } = props

  const {
    input: { value: isAtestadoDigitalChecked, onChange: onChangeAtestadoDigitalChecked },
  } = usePecField({ name: name, subscription: { value: true } })

  const alert = useAlert()
  const isCidadaoEmailEmpty = isEmpty(cidadaoEmail)
  const { PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED } = useFlags()
  const { data: infoPrescricaoDigital, loading } = usePrescricaoDigitalEnabledQuery()
  const { analytics } = useFirebase()

  useEffect(() => {
    onEmailCidadaoChange(isAtestadoDigitalChecked && isCidadaoEmailEmpty && PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED)

    if (hasCid10) {
      onChangeAtestadoDigitalChecked(false)
    }
  }, [
    PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED,
    hasCid10,
    isAtestadoDigitalChecked,
    isCidadaoEmailEmpty,
    onChangeAtestadoDigitalChecked,
    onEmailCidadaoChange,
  ])

  if (PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED && loading) {
    return (
      <HFlow style={styles.loadingContainer}>
        <Spinner />
        <Text color='primary' fontWeight='bold'>
          Carregando e-mail
        </Text>
      </HFlow>
    )
  }

  if (!PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED || !infoPrescricaoDigital?.info?.isPrescricaoDigitalEnabled) {
    return null
  }

  const handleEmailChange = () => {
    alert('success', 'E-mail atualizado com sucesso.')
    analytics.logEvent('click_atualizar_email_atestado_digital')
  }

  const handleEmailEdit = () => analytics.logEvent('click_editar_email_atestado_digital')

  return (
    <HFlow style={[styles.container, style]}>
      <AtestadoAssinaturaDigitalGerarCheckbox name={name} hasCid10={hasCid10} />
      <EmailUpdateForm
        cidadaoId={cidadaoId}
        cidadaoEmail={cidadaoEmail}
        onSubmitSuccess={handleEmailChange}
        disabled={!isAtestadoDigitalChecked}
        initialValues={{ email: cidadaoEmail }}
        onDirtyChange={onDirtyChange}
        onEditarClick={handleEmailEdit}
        canceled={canceled}
        onCancel={onCancel}
      />
    </HFlow>
  )
})

const styles = {
  container: css`
    width: 100%;
  `,
  externalStylesDefault: css`
    padding-left: 0rem;
  `,
  loadingContainer: css`
    padding-left: 0.5rem;
    color: ${theme.pallete.primary.main};
    width: 100%;
    display: flex;
    grid-gap: 0.5rem;
  `,
}
