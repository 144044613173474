import { HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { LabelEvolucaoCuidadoCompartilhadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoEvolucaoDetalhes } from '../HistoricoCuidadoCompartilhadoPanel'

interface HistoricoCuidadoCompartilhadoAgendamentoProps {
  cuidadoCompartilhadoEvolucao: CuidadoCompartilhadoEvolucaoDetalhes
}

export function HistoricoCuidadoCompartilhadoAgendamento(props: HistoricoCuidadoCompartilhadoAgendamentoProps) {
  const { cuidadoCompartilhadoEvolucao } = props

  return (
    <VFlow vSpacing={0.1}>
      <Text fontWeight='bold'>{LabelEvolucaoCuidadoCompartilhadoRecord[cuidadoCompartilhadoEvolucao.conduta]}</Text>
      <Text>Agendado para {formatDateAndHoursMinutes(cuidadoCompartilhadoEvolucao.agenda?.horarioInicial)}.</Text>
      {cuidadoCompartilhadoEvolucao.agenda?.observacao && (
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>Observações: </Text>
          <Text dangerouslySetInnerHTML={{ __html: cuidadoCompartilhadoEvolucao.agenda?.observacao }} />
        </HFlow>
      )}
    </VFlow>
  )
}
