import { DateRange } from 'bold-ui'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import { AtendimentosQueryInput, StatusAtendimento } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { AtendimentosRegistroTardioFilterModel } from './filter/useListaRegistroTardioFilterDefault'

export const convertListaRegistroTardioStateToInput = (
  state: AtendimentosRegistroTardioFilterModel,
  periodoDefault: DateRange
): AtendimentosQueryInput => {
  const { isSomenteNaoFinalizados } = state.filter
  const periodoNaoFinalizado = {
    startDate: periodoDefault.startDate.getTime(),
    endDate: subDays(periodoDefault.endDate, 1).getTime(),
  }

  const dataInicio = isSomenteNaoFinalizados
    ? periodoNaoFinalizado.startDate
    : !isUndefinedOrNull(state.filter.periodo.startDate)
    ? startOfDay(new Date(state.filter.periodo.startDate)).getTime()
    : null

  const dataFim = isSomenteNaoFinalizados
    ? periodoNaoFinalizado.endDate
    : !isUndefinedOrNull(state.filter.periodo.endDate)
    ? endOfDay(new Date(state.filter.periodo.endDate)).getTime()
    : null

  return {
    query: state.filter.query,
    ordem: state.filter.ordem,
    somenteMeusAtendimentos: state.filter.somenteMeusAtendimentos,
    statusAtendimento:
      !state.filter.statusAtendimento || state.filter.statusAtendimento.length === 0
        ? [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.AGUARDANDO_ATENDIMENTO]
        : state.filter.statusAtendimento || [],
    dataInicio,
    dataFim,
    responsaveisIds: state.filter.responsaveis?.map((value) => value.id),
    isRegistroTardio: true,
    pageParams: state.pageParams,
  }
}
